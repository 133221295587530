import axios from 'axios';
import config from '../config';
import { PotalCommDto } from '../types/PotalCommDto';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import { CreatePrdctDto, PrdctDetailDto, PrdctDto } from '../types/PrdctDto';
import accessTokenPortalApi, { accessTokenMapprimeApi } from './api';

interface ApiResponseList {
    status: string;
    data?: any[];
    meta?: PotalCommDto
    error?: {
        code: string;
        message: string;
    };
}

export async function findPrdctList(sltnId: string): Promise<SuccessResponse<PrdctDto[], PotalCommDto> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.get<SuccessResponse<PrdctDto[], PotalCommDto> | ErrorResponse>(
            `/prdct/${sltnId}`,
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to fetch product list',
                },
            };
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            console.error('Unauthorized: Access token expired or invalid.');
        } else {
            console.error('Error fetching product list:', error);
        }

        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while fetching the product list',
            },
        };
    }
}

export async function findPrdctItemList(sltnId: string): Promise<SuccessResponse<PrdctDetailDto[], PotalCommDto> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.get<SuccessResponse<PrdctDetailDto[], PotalCommDto> | ErrorResponse>(
            `/prdct/item/${sltnId}`,
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to fetch product item list',
                },
            };
        }
    } catch (error) {
        console.error('Error fetching product item list:', error);
        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while fetching the product item list',
            },
        };
    }
}

export async function updatePrdct({ sltnId, createPrdctDto }: { sltnId: string, createPrdctDto: CreatePrdctDto }): Promise<SuccessResponse<CreatePrdctDto> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.put<SuccessResponse<CreatePrdctDto> | ErrorResponse>(
            `/prdct/${sltnId}`,
            createPrdctDto,
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to update product',
                },
            };
        }
    } catch (error) {
        console.error('Error updating product:', error);
        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while updating the product',
            },
        };
    }
}

// 현재 사용중인 구독 관련 목록을 조회한다.
export async function getBuyUseList(): Promise<any | null> {
    try {
        const access_token = sessionStorage.getItem('access_token');

        const response = await axios.get<Promise<ApiResponseList> | null>(
            `${config.portalApiUrl}/buy/use`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Failed to select:', error);
        return {
            status: 'error',
            error: {
                code: 'INTERNAL_SERVER_ERROR',
                message: error,
            },
        };
    }
};