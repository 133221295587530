import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { postCodeGrpAsync } from '../../modules/siteMng';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { CreateCodeGrpDto } from '../../types/common/CreateCodeGrpDto';

interface SearchFormProps {
    openModel : boolean;
    handleModalClose :  (refreshYn :string) => void; // onChange prop 추가
}

const CreateCodeGrpModal:FC<SearchFormProps> = ({ openModel , handleModalClose }) => {

    const dispatch = useDispatch();

    const codeGrpInsert  = useSelector((state:RootState) => state.siteMng.codeGrpInsert);

    const initialParams: CreateCodeGrpDto = {
        cdGrpId    : ""
      , cdGrpNm    : ""
      , cdGrpExpln : ""
    };

    const [createCodeGrpParams, setCreateCodeGrp] = useState<CreateCodeGrpDto>(initialParams);

    // modal 오픈시 상세정보 조회
  useEffect(() => {
    setCreateCodeGrp(initialParams);
  },[openModel]);
    
    const inputHandleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setCreateCodeGrp((prev) => ({ ...prev, [name]: value}));
    };

    const onGrpSave = () => {
        if (!createCodeGrpParams.cdGrpId) {
            alert("그룹코드를 입력해 주십시오.");
            return;
        }

        if (!createCodeGrpParams.cdGrpNm) {
            alert("그룹코드명를 입력해 주십시오.");
            return;
        }

        dispatch(postCodeGrpAsync.request(createCodeGrpParams));
    }

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (codeGrpInsert && codeGrpInsert.data && createCodeGrpParams.cdGrpId) {
            const status = codeGrpInsert.data.status;
    
            if (status == "error") {
                alert(codeGrpInsert.data.error.message);
            } else if (status == "success") {
                alert("저장 되었습니다.");
                returnModalClose("Y");
            }
        }
    },[codeGrpInsert]);

    const returnModalClose = (refresh:string) => {
        handleModalClose(refresh);
    }

    return (
        <Modal show={openModel} onHide={() => returnModalClose('N')}>
            <Modal.Header closeButton>
                <Modal.Title>그룹 공통코드 등록</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className='dash-ti mb-2'>필수 정보</h3>
                <Table bordered size="sm">
                    <colgroup>
                        <col style={{ width: '120px' }} />
                        <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                        <tr>    
                            <th><span style={{color:"red"}}>＊</span>그룹코드</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeGrpParams.cdGrpId} aria-label=".form-control-sm example" id="cdGrpId" name="cdGrpId" onChange={inputHandleChange} />
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span>그룹코드명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeGrpParams.cdGrpNm}  aria-label=".form-control-sm example" id="cdGrpNm" name="cdGrpNm" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>설명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeGrpParams.cdGrpExpln}  aria-label=".form-control-sm example" id="cdGrpExpln" name="cdGrpExpln" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onGrpSave}>
                    저장
                </Button>
                <Button variant="secondary" onClick={() => returnModalClose('N')}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateCodeGrpModal;