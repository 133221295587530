import axios from 'axios';
import { SearchCommonDto } from '../types/common/SearchCommonDto';
import { CreateCodeGrpDto } from '../types/common/CreateCodeGrpDto';
import { UpdateCodeGrpDto } from '../types/common/UpdateCodeGrpDto';
import { CreateCodeDto } from '../types/common/CreateCodeDto';
import { UpdateCodeDto } from '../types/common/UpdateCodeDto';

import {PotalCommDto} from '../types/PotalCommDto';
import config from '../config';
import { AsyncState } from '../modules/lib/reducerUtils';
import accessTokenPortalApi from './api';

interface ApiResponseList {
  status: string;
  data?: any[];
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface ApiResponse {
  status: string;
  data?: any;
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface SuccessResponse<T> {
  status: 'success';
  data: T;
}

interface ErrorResponse {
  status: 'error';
  error: {
    code: string;
    message: string;
  };
}


// 공통코드 그룹 정보 관리
export async function getCodeGrpList(searchCommonDto: SearchCommonDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<ApiResponseList>(
      `/admin/codeGrp`,
      {
        params :  searchCommonDto,
      }
    );

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
}

export async function getCodeGrp(cdGrpId:string): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<ApiResponse | null>(
      `/admin/codeGrp/`+cdGrpId,
    );

    console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function postCodeGrp(createCodeGrpDto: CreateCodeGrpDto): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.post<CreateCodeGrpDto>(
      `/admin/codeGrp`,
      createCodeGrpDto,      
    );

    console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error:any) {
    
    console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: "저장시 오류가 발생하였습니다."
      },
    };
  }
};

export async function putCodeGrp( { cdGrpId, updateCodeGrpDto }: { cdGrpId: string, updateCodeGrpDto: UpdateCodeGrpDto }): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.put<UpdateCodeGrpDto>(
      `/admin/codeGrp/`+cdGrpId,
      updateCodeGrpDto,
    );

    console.log('수정 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    console.log('수정 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: "수정시 오류가 발생하였습니다.",
      },
    };
  }
};

export async function deleteCodeGrp(cdGrpId:string): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.delete<any>(
      `/admin/codeGrp/`+cdGrpId,
    );

    console.log('삭제 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    console.log('삭제 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: "삭제시 오류가 발생하였습니다.",
      },
    };
  }
};


// 공통코드  정보 관리
export async function getCodeList(searchCommonDto: SearchCommonDto): Promise<any | null> {
    try {
      const response = await accessTokenPortalApi.get<ApiResponseList>(
        `/admin/code`,
        {
          params :  searchCommonDto,
        }
      );
  
      return response.data; // 업데이트된 사용자 데이터를 반환
      
    } catch (error) {
      console.error('Error updated user:', error);
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: '조회시 오류가 발생하였습니다.',
        },
      };
    }
  }
  
  export async function getCode(codeGrp:string): Promise<any | null> {
    try {
      const response = await accessTokenPortalApi.get<ApiResponse | null>(
        `/admin/code/`+codeGrp,
      );
  
      console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
      
      return response.data;
    } catch (error) {
      console.error('Failed to select:', error);
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: '조회시 오류가 발생하였습니다.',
        },
      };
    }
  };
  
  export async function postCode(createCodeDto: CreateCodeDto): Promise<any | null> {
    try {
      
      const response = await accessTokenPortalApi.post<CreateCodeDto>(
        `/admin/code`,
        createCodeDto,      
      );
  
      console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));
  
     //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
      return response.data;
    } catch (error:any) {
      
      console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: "저장시 오류가 발생하였습니다."
        },
      };
    }
  };

  export async function putCode( { codeGrp, updateCodeDto }: { codeGrp: string, updateCodeDto: UpdateCodeDto } ): Promise<any | null> {
    try {
      const response = await accessTokenPortalApi.put<UpdateCodeDto>(
        `/admin/code/`+codeGrp,
        updateCodeDto,
      );
  
      console.log('수정 결과 api:', JSON.stringify(response.data, null, 2));
  
      return response.data;
    } catch (error) {
      console.log('수정 결과 error api:', JSON.stringify(error, null, 2));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: "수정시 오류가 발생하였습니다.",
        },
      };
    }
  };
  
  export async function deleteCode(codeGrp:string): Promise<any | null> {
    try {
      
      const response = await accessTokenPortalApi.delete<any>(
        `/admin/code/`+codeGrp,
      );
  
      console.log('삭제 결과 api:', JSON.stringify(response.data, null, 2));
  
      return response.data;
    } catch (error) {
      console.log('삭제 결과 error api:', JSON.stringify(error, null, 2));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: "삭제시 오류가 발생하였습니다.",
        },
      };
    }
  };