import axios from 'axios';
import { CreateUserDto, ProfileDto, SelectUserDto, UserDto } from '../types/UserDto';
import config from '../config';
import { PotalCommDto } from '../types/PotalCommDto';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import accessTokenPortalApi from './api';

export async function findUserList(selectUserDto: SelectUserDto): Promise<SuccessResponse<UserDto[], PotalCommDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<UserDto[], PotalCommDto> | ErrorResponse>(
      `/user`,
      {
        params: selectUserDto,
      }
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error && error.message === 'Unauthorized') {
      console.error('Unauthorized: Redirect to login');
      // 컴포넌트에서 리디렉션 처리
      throw new Error('UNAUTHORIZED');
    }
    console.error('Error fetching user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch user list',
      },
    };
  }
}

export async function findUser(userId: string): Promise<SuccessResponse<ProfileDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<ProfileDto> | ErrorResponse>(
      `/user/${userId}`,
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      console.error('Unauthorized: Access token expired or invalid.');
    } else {
      console.error('Error fetching user:', error);
    }
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch user list',
      },
    };
  }
}

export async function updateUser({ userId, userDto }: { userId: string, userDto: UserDto }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.put<SuccessResponse<null> | ErrorResponse>(
      `/user/${userId}`,
      userDto,
    );

    if (response.data.status === 'success') {
      console.log('User updated successfully');
      return response.data; // 업데이트된 사용자 데이터를 반환
    } else {
      console.log('Update failed with message:', response.data.error.message);
      return response.data; // 에러 데이터를 반환
    }
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to updated user',
      },
    };
  }
}

export async function deleteUser(id: string[]): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.delete<SuccessResponse<null> | ErrorResponse>(
      `/user/list/delete`,
      {
        data: { id },
      }
    );

    if ((await response.data).status === 'success') {
      console.log('success');
      return response.data; // userId를 반환
    } else if ((await response.data).status === 'error') {
      console.log((await response.data));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete user',
        },
      };
    } else {
      console.log('else');
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete user',
        },
      };
    }
  } catch (error) {
    console.error('Error delete user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete user',
      },
    };
  }
}

