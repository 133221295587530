import { FC, useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { format } from 'date-fns';
import Pagination from "../../components/Pagination";
import { OgnzDto, SelectOgnzDto } from "../../types/OgnzDto";
import { createOgnzAsync, deleteOgnzAsync, findOgnzAsync, findOgnzListAsync, updateOgnzAsync } from "../../modules/ognz";
import { JsxEmit } from "typescript";

const OgnzList: FC = () => {
    const [ognzDto, setOgnzDto] = useState<OgnzDto>({
        ognzId: "",
        ognzName: "",
        ognzDesc: "",
        ognzScrtnId: "",
        useYn: "",
        regUserId: "",
        regDt: "",
        modUserId: "",
        users: [],
    });
    const createOgnzState = useSelector((state: RootState) => state.ognz.createOgnz);
    const dispatch = useDispatch();
    const ognzList = useSelector((state: RootState) => state.ognz.ognzList);
    const selectedOgnz = useSelector((state: RootState) => state.ognz.selectedOgnz);
    const [selectedOgnzs, setSelectedOgnzs] = useState<string[]>([]);   // 선택된 사용자들
    const [show, setShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [searchParams, setSearchParams] = useState<SelectOgnzDto>({
        searchType: 'ognz_nm', // 검색어 조건
        searchKeyword: '',
        searchOrderBy: '',
        currentPageNo: 1,
    });

    // 초기화 버튼 클릭 핸들러
    const handleReset = () => {
        // 검색 조건 초기화
        setSearchParams({
            searchType: '',
            searchKeyword: '',
            searchOrderBy: '',
            currentPageNo: 1,
        });
    };

    // 검색어와 권한 변경 핸들러
    const handleInputChange = (field: keyof SelectOgnzDto, value: string) => {
        setSearchParams(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleClose = () => setShow(false);
    const handleShow = async (ognzId: string) => {
        dispatch(findOgnzAsync.request(ognzId));
        setShow(true)
    };

    // 전체 선택/해제 핸들러
    const handleAllChecked = () => {
        if (ognzList.data?.data) {
            if (selectedOgnzs.length === ognzList.data?.data?.length) {
                setSelectedOgnzs([]); // 모두 선택된 상태면 모두 해제
            } else {
                const allOgnzIds = ognzList.data?.data?.map((ognz: OgnzDto) => ognz.ognzId);
                setSelectedOgnzs(allOgnzIds); // 모두 선택
            }
        }
    };

    // 개별 체크박스 선택/해제 핸들러
    const handleCheckboxChange = (ognzId: string) => {
        if (selectedOgnzs.includes(ognzId)) {
            setSelectedOgnzs(selectedOgnzs.filter(id => id !== ognzId)); // 이미 선택된 경우 제거
        } else {
            setSelectedOgnzs([...selectedOgnzs, ognzId]); // 선택되지 않은 경우 추가
        }
    };

    // 기관 추가 버튼 클릭
    const handleCreateModalShow = () => {
        setOgnzDto({
            ognzId: "",
            ognzName: "",
            ognzDesc: "",
            ognzScrtnId: "",
            useYn: "",
            regUserId: "",
            regDt: "",
            modUserId: "",
            users: [],
        });
        setCreateModalShow(true)
    };

    // 모달 창 닫기 함수
    const handleCreateModalClose = () => {
        setCreateModalShow(false);
    };

    // 추가 버튼 클릭 핸들러
    const handleCreateClick = () => {
        dispatch(createOgnzAsync.request(ognzDto));
    };

    // 삭제 버튼 클릭 핸들러
    const handleDeleteClick = async () => {
        if (selectedOgnzs?.length > 0) {
            const confirmDelete = window.confirm("정말로 선택한 기관을 삭제하시겠습니까?");
            if (confirmDelete) {
                const result = await dispatch(deleteOgnzAsync.request(selectedOgnzs)); // Dispatch the delete action

                if (result) {
                    dispatch(findOgnzListAsync.request(searchParams));
                    setSelectedOgnzs([]);
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제 실패.');
                }
            }
        } else {
            alert("삭제할 기관을 선택하세요.");
        }
    };

    const handleUpdate = (ognzDto: OgnzDto) => {
        dispatch(updateOgnzAsync.request({ ognzId: ognzDto.ognzId, ognzDto: ognzDto }))
        alert("기관 정보가 변경되었습니다.");
        window.location.reload();
    }

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSearch(1);
    };

    const onSearch = async (pageNo: number) => {
        setSearchParams({ ...searchParams, currentPageNo: pageNo })
        dispatch(findOgnzListAsync.request({
            ...searchParams,
            currentPageNo: pageNo,
        }));
    }

    useEffect(() => {
        dispatch(findOgnzListAsync.request(searchParams));
    }, [dispatch]);

    useEffect(() => {
        if (selectedOgnz.data) {
            setOgnzDto({
                ...selectedOgnz.data.data,
            });
        }
    }, [selectedOgnz]);

    useEffect(() => {
        console.log(`createOgnzState.data: ${JSON.stringify(createOgnzState.error)}`);
        if (createOgnzState.data) {
            alert('기관이 성공적으로 추가되었습니다.');
            setCreateModalShow(false);
            dispatch(findOgnzListAsync.request(searchParams));
        }
        if (createOgnzState.error) {
            alert('기관번호가 존재합니다.');
        }
    }, [createOgnzState, dispatch, searchParams]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='기관(기업) 조회'>
                    {/* 검색 폼 */}
                    <div className='row'>
                        <form onSubmit={handleSearch}>
                            <div className='dash-wrap-cont dash-info-wrap'>
                                {/* 검색어 입력 필드 */}
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        검색어
                                    </div>
                                    <div className="col-8">
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            placeholder="기관명"
                                            aria-label=".form-control-sm example"
                                            value={searchParams.searchKeyword}
                                            onChange={(e) => handleInputChange('searchKeyword', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center gap-2 mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={handleReset}
                                    >
                                        초기화
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                    >
                                        검색
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* 유저 목록 테이블 */}
                    <div className="row">
                        <div className="display-count mb-1 d-flex justify-content-between">
                            <span>총 기관 수 : {ognzList.data?.meta?.totalCnt ? ognzList.data.meta.totalCnt.toLocaleString() : 0}</span>
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-secondary btn-sm m-1" onClick={handleCreateModalShow}>기관 추가</button>
                                <button type="button" className="btn btn-secondary btn-sm m-1" onClick={handleDeleteClick}>선택 기관 삭제</button>
                            </div>
                        </div>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th className='text-center'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="checkAll"
                                            checked={ognzList.data ? selectedOgnzs?.length === ognzList.data?.data?.length : false}
                                            onChange={handleAllChecked}
                                        />
                                    </th>
                                    <th className='text-center'>번호</th>
                                    <th className='text-center'>기관명</th>
                                    <th className='text-center'>등록일</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ognzList.loading ? (
                                    <tr>
                                        <td className="text-center"> loading...</td>
                                    </tr>
                                ) : ognzList.data?.data?.length > 0 ? (
                                    ognzList.data?.data?.map((ognz: OgnzDto, index: number) => (
                                        <tr key={index}>
                                            <td className='text-center'>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectedOgnzs.includes(ognz.ognzId)}
                                                    onChange={() => handleCheckboxChange(ognz.ognzId)}
                                                />
                                            </td>
                                            <td className='text-center'>{
                                                (ognzList.data?.meta?.totalCnt -
                                                (ognzList.data?.meta?.currentPageNo - 1) * ognzList.data?.meta?.pageSize - index).toLocaleString()
                                            }
                                            </td>
                                            <td>
                                                <button className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={() => handleShow(ognz.ognzId)}>
                                                    {ognz.ognzName}
                                                </button>
                                            </td>
                                            <td className='text-center'>{new Date(ognz.regDt!).toLocaleDateString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {ognzList.data?.meta ? (
                            <Pagination key="1" pageingDto={ognzList.data?.meta} onClick={onSearch} />
                        ) : (<span></span>)
                        }
                    </div>
                </ContentLayout>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>기관 정보</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='dash-ti mb-2'>필수 정보</h3>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기관명</th>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={ognzDto.ognzName}
                                            onChange={(e) => setOgnzDto({
                                                ...ognzDto,
                                                ognzName: e.target.value
                                            })}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            닫기
                        </Button>
                        <Button variant="primary" onClick={() => handleUpdate(ognzDto)}>
                            변경
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={createModalShow} onHide={handleCreateModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>기관 정보</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='dash-ti mb-2'>필수 정보</h3>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>번호</th>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={ognzDto.ognzId}
                                            onChange={(e) => {
                                                // 숫자만 입력할 수 있도록 필터링
                                                const value = e.target.value;
                                                if (!isNaN(Number(value))) {
                                                    setOgnzDto({
                                                        ...ognzDto,
                                                        ognzId: value
                                                    });
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>기관명</th>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={ognzDto.ognzName}
                                            onChange={(e) => setOgnzDto({
                                                ...ognzDto,
                                                ognzName: e.target.value
                                            })}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setCreateModalShow(false)}>
                            닫기
                        </Button>
                        <Button variant="primary" onClick={() => handleCreateClick()}>
                            추가
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )

}
export default OgnzList;
