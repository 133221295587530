import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './containers/NotFound';
import Login from './pages/login';
import MainPage from './pages/main';

import NoticeList from './pages/support/NoticeList';
import NoticeWrite from './pages/support/NoticeWrite';

import FaqList from './pages/support/FaqList';
import FaqWrite from './pages/support/FaqWrite';

import ContactUsList from './pages/support/ContactUsList';
import ContactUsDetail from './pages/support/ContactUsDetail';
import ContactUsWrite from './pages/support/ContactUsWrite';

import UserList from './pages/user/UserList';
import FAQ from './pages/faq';
import List from './pages/board/List';
import Read from './pages/board/Read';
import Write from './pages/board/Write';
import OgnzList from './pages/ognz/OgnzList';
import Prdct from './pages/prdct/Prdct';
import UserGroupList from './pages/user/UserGroupList';
import UserAuthList from './pages/authmng/UserAuthList';
import CntnLog from './pages/cntn/CntnLog';
import BuyList from './pages/buy/BuyList';
import Dashboard from './pages/dashboard/Dashboard';
import CodeGrp  from './pages/siteMng/CodeGrp';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/userList" element={<UserList />}></Route>
          <Route path="/ognzMngList" element={<UserGroupList />}></Route>
          <Route path="/ognzList" element={<OgnzList />}></Route>
          <Route path="/userAuth" element={<UserAuthList />}></Route>

          <Route path="/prdct" element={<Prdct />}></Route>
          <Route path="/scrtn" element={<BuyList />}></Route>
          
          <Route path="/faqList" element={<FaqList />}></Route>
          <Route path="/faqWrite" element={<FaqWrite />}></Route>
          <Route path="/faqWrite/:id" element={<FaqWrite />}></Route>

          <Route path="/noticeList" element={<NoticeList />}></Route>
          <Route path="/noticeWrite" element={<NoticeWrite />}></Route>
          <Route path="/noticeWrite/:id" element={<NoticeWrite />}></Route>

          <Route path="/contactUsList" element={<ContactUsList />}></Route>
          <Route path="/contactUsDetail/:id" element={<ContactUsDetail />}></Route>
          <Route path="/contactUsWrite" element={<ContactUsWrite />}></Route>
          <Route path="/contactUsWrite/:id" element={<ContactUsWrite />}></Route>
          
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/list" element={<List />}></Route>
          <Route path="/read" element={<Read />}></Route>
          <Route path="/write" element={<Write />}></Route>
          <Route path="*" element={<NotFound />}></Route>

          <Route path="/cntnLog" element={<CntnLog />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>

          <Route path="/codeGrp" element={<CodeGrp />}></Route>
          

        </Routes>
    </BrowserRouter>
  );
}
export default App;
