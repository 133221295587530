import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../modules/";
import { getCommCdListAsync } from '../modules/common';

interface SearchFormProps {
    objectId : string;
    cdGrpId  : string;
    onChange?:  (id : string , value: string) => void; // onChange prop 추가
}

const CheckBoxForm:FC<SearchFormProps> = ({ objectId, cdGrpId, onChange  }) => {    

    const dispatch = useDispatch();
    let comboInfo = useSelector((state: RootState) => state.common.data);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    
    interface Combo {
        cdGrpId: string; 
        cmCdId : string;
        cdVl : string;
    }

    let checkboxList = [];
    let checkboxListData: Combo[] = []; // 타입 명시

    if (comboInfo.data && comboInfo.data.data) {
        checkboxListData = comboInfo.data.data.filter((combo: any) => combo.cdGrpId === cdGrpId);
    }

    console.log(checkboxListData);

    useEffect(() => {
        if (onChange) {
            let result = ""; 
            for (let i=0;i<checkedItems.length;i++) {
               if (i > 0) {
                  result += ","; 
               }
    
               result += checkedItems[i];
            }
            
            if (onChange) {
                onChange(objectId , result);
            }
        }
    }, [checkedItems]);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getCommCdListAsync.request({ }));
        };

        fetchData();
    }, []);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // 전체 체크박스
        if (value === "") {
            // 전체 체크박스 클릭 시
            if (isAllChecked) {
                await setCheckedItems([]); // 전체 해제
                setIsAllChecked(false);
            } else {
                await setCheckedItems(checkboxListData.map((combo) => combo.cmCdId)); // 전체 선택
                setIsAllChecked(true);
            }
        } else {
            // 개별 체크박스 클릭 시
            if (checkedItems.includes(value)) {
                await setCheckedItems(checkedItems.filter(item => item !== value)); // 체크 해제
            } else {
                await setCheckedItems([...checkedItems, value]); // 체크 추가
            }
        }  
    };

    var listCnt = 0;
    
    if (comboInfo.data && comboInfo.data.data) {

        for (let i=0;i<checkboxListData.length;i++) {
            let combo = checkboxListData[i];
            let nwObjectId = objectId + "_" + i;

            if (cdGrpId == combo.cdGrpId) {
                listCnt++;
                checkboxList.push(
                    <div className="form-check form-check-inline">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value={combo.cmCdId} 
                            id={nwObjectId}
                            name={nwObjectId}
                            checked={checkedItems.includes(combo.cmCdId)}
                            onChange={handleChange} />
                        <label className="form-check-label" htmlFor={nwObjectId}>
                           {combo.cdVl}
                        </label>
                    </div>
                );
            }
        }
    }
    
    return (
        <div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" value="" id={objectId + "_All"} onChange={(e) => handleChange(e)} checked={checkedItems.length == listCnt ? true : false} />
                <label className="form-check-label" htmlFor={objectId + "_All"} >
                    전체
                </label>
            </div>
            {checkboxList}
        </div>
    )    
}

export default CheckBoxForm;