import { combineReducers } from 'redux';
import user from './user/reducer';
import count from './count/reducer';
import { authSaga } from './authuser'; 
import support from './support/reducer';
import common from './common/reducer';
import usergroup from './usergroup/reducer';
import { all, call, fork } from 'redux-saga/effects';
import authReducer from './authuser/reducer';
import { supportSaga } from './support';
import { commonSaga } from './common';
import { userSaga } from './user';
import { ognzSaga } from './ognz';
import { userGroupSaga } from './usergroup';
import ognz from './ognz';
import prdct, { prdtcSaga } from './prdct';
import buy, { buySaga } from './buy';
import cntn, { cntnSaga } from './cntn';
import stat, { statSaga } from './stat';
import siteMng, { siteMngSaga } from './siteMng';
import dashboard from './dashboard/reducer';
import { dashboardSaga as dashboardSaga } from './dashboard';

const rootReducer = combineReducers({
  auth: authReducer,
  count,
  user,
  support,
  common,
  ognz,
  usergroup,
  prdct,
  cntn,
  buy,
  stat,
  dashboard,
  siteMng,
});

export type RootState = ReturnType<typeof rootReducer>;

//saga
function* rootSaga() {
  yield all([
    authSaga(),
    supportSaga(),
    commonSaga(),
    userSaga(),
    ognzSaga(),
    userGroupSaga(),
    prdtcSaga(),
    cntnSaga(),
    buySaga(),
    statSaga(),
    dashboardSaga(),
    siteMngSaga(),
  ]);
}

export { rootReducer, rootSaga }
export default rootReducer