import { createReducer } from 'typesafe-actions';
import { PrdctState, PrdctAction } from './types';
import { createPrdctAsync, findPrdctItemListAsync, findPrdctListAsync, getBuyUseListAsync } from './actions';
import { asyncState } from '../lib/reducerUtils';

const initialState: PrdctState = {
  prdctList: asyncState.initial(),
  prdctItemList: asyncState.initial(),
  data: asyncState.initial(),
  buyUserList: asyncState.initial(),
  dashboardInfo: asyncState.initial(),
};

const prdct = createReducer<PrdctState, PrdctAction>(initialState)
.handleAction(
  findPrdctListAsync.request,
  (state) =>({
    ...state,
    prdctList: asyncState.load(),
  })
)
.handleAction(
  findPrdctListAsync.success,
  (state, action) => ({
    ...state,
    prdctList: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  findPrdctListAsync.failure,
  (state, action) => ({
    ...state,
    prdctList: asyncState.error(action.payload),  // 목록 실패
  })
).handleAction(
  findPrdctItemListAsync.request,
  (state) =>({
    ...state,
    prdctItemList: asyncState.load(),
  })
)
.handleAction(
  findPrdctItemListAsync.success,
  (state, action) => ({
    ...state,
    prdctItemList: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  findPrdctItemListAsync.failure,
  (state, action) => ({
    ...state,
    prdctItemList: asyncState.error(action.payload),  // 목록 실패
  })
).handleAction(
  createPrdctAsync.request,
  (state) =>({
    ...state,
    data: asyncState.load(),
  })
)
.handleAction(
  createPrdctAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  createPrdctAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
  })
)
.handleAction(
  getBuyUseListAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getBuyUseListAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    buyUserList: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getBuyUseListAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    buyUserList: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
);

export default prdct;