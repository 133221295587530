import { createReducer } from 'typesafe-actions';
import { AuthState } from './types';
import { loginAsync, logoutAsync, postFindUserIdAsync } from './actions';
import { AnyAction } from 'redux';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: AuthState = {
  data: asyncState.initial(),
};

const authReducer = createReducer<AuthState, AnyAction>(initialState)
.handleAction(
  transformToArray(loginAsync),
  createAsyncReducer(loginAsync, 'data')
).handleAction(
  transformToArray(logoutAsync),
  createAsyncReducer(logoutAsync, 'data')
).handleAction(
  transformToArray(postFindUserIdAsync),
  createAsyncReducer(postFindUserIdAsync, 'data')
);

export default authReducer;