import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { postCodeAsync } from '../../modules/siteMng';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { CreateCodeDto } from '../../types/common/CreateCodeDto';

interface SearchFormProps {
    cdGrpId   : string;
    openModel : boolean;
    handleModalClose :  (refreshYn :string) => void; // onChange prop 추가
}

const CreateCodeModal:FC<SearchFormProps> = ({ openModel , cdGrpId , handleModalClose }) => {

    const dispatch = useDispatch();

    const codeInsert  = useSelector((state:RootState) => state.siteMng.codeInsert);

    const initialParams: CreateCodeDto = {
        cdGrpId: "",
        cmCdId: "",
        cdVl: "",
        cdDesc: "",
        cdOrr: "",
        etcCn1: "",
        etcCn2: "",
        etcCn3: ""
    };

    const [createCodeParams, setCreateCode] = useState<CreateCodeDto>(initialParams);

  // modal 오픈시 상세정보 조회
  useEffect(() => {
    setCreateCode(initialParams);

    setCreateCode((prev) => ({ ...prev, cdGrpId : cdGrpId}));

  },[openModel]);
    
    const inputHandleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name == "cdOrr") {
            // 숫자와 빈 문자열만 허용
            if (value === '' || /^\d+$/.test(value)) {
                setCreateCode((prev) => ({ ...prev, [name]: value}));
            } else {
                setCreateCode((prev) => ({ ...prev, [name]: ""}));
            }
        } else {
            setCreateCode((prev) => ({ ...prev, [name]: value}));
        }        
    };

    const onCodeSave = () => {
        if (!createCodeParams.cmCdId) {
            alert("코드를 입력해 주십시오.");
            return;
        }

        if (!createCodeParams.cdVl) {
            alert("코드명를 입력해 주십시오.");
            return;
        }

        if (!createCodeParams.cdOrr) {
            alert("순서를 입력해 주십시오.");
            return;
        }

        dispatch(postCodeAsync.request(createCodeParams));
    }

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (codeInsert && codeInsert.data && createCodeParams.cdGrpId && createCodeParams.cmCdId) {
            const status = codeInsert.data.status;
    
            if (status == "error") {
                alert(codeInsert.data.error.message);
            } else if (status == "success") {
                alert("저장 되었습니다.");
                returnModalClose("Y");
            }
        }
    },[codeInsert]);

    const returnModalClose = (refresh:string) => {
        handleModalClose(refresh);
    }

    return (
        <Modal show={openModel} onHide={() => returnModalClose('N')} style={{top:"20%"}}>
            <Modal.Header closeButton>
                <Modal.Title>공통코드 등록</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className='dash-ti mb-2'>필수 정보</h3>
                <Table bordered size="sm">
                    <colgroup>
                        <col style={{ width: '120px' }} />
                        <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                        <tr>    
                            <th><span style={{color:"red"}}>＊</span> 그룹코드</th>
                            <td style={{ paddingLeft: '10px' }}>
                                {createCodeParams.cdGrpId}
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span> 코드</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.cmCdId}  aria-label=".form-control-sm example" id="cmCdId" name="cmCdId" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span> 코드명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.cdVl}  aria-label=".form-control-sm example" id="cdVl" name="cdVl" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span> 순서</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.cdOrr}  aria-label=".form-control-sm example" id="cdOrr" name="cdOrr" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>설명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.cdDesc}  aria-label=".form-control-sm example" id="cdDesc" name="cdDesc" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타1</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.etcCn1}  aria-label=".form-control-sm example" id="etcCn1" name="etcCn1" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타2</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.etcCn2}  aria-label=".form-control-sm example" id="etcCn2" name="etcCn2" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타3</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={createCodeParams.etcCn3}  aria-label=".form-control-sm example" id="etcCn3" name="etcCn3" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCodeSave}>
                    저장
                </Button>
                <Button variant="secondary" onClick={() => returnModalClose('N')}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateCodeModal;