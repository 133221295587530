import axios from 'axios';
import config from '../config';
import { PotalCommDto } from '../types/PotalCommDto';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import { OgnzDto, SelectOgnzDto } from '../types/OgnzDto';
import accessTokenPortalApi from './api';

export async function findOgnzList(selectOgnzDto: SelectOgnzDto): Promise<SuccessResponse<OgnzDto[], PotalCommDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<OgnzDto[], PotalCommDto> | ErrorResponse>(
      `/ognz`,
      {
        params: selectOgnzDto,
      }
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching ognz:', error);

    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch ognz list',
      },
    };
  }
}

export async function findOgnz(ognzId: string): Promise<SuccessResponse<OgnzDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<OgnzDto> | ErrorResponse>(
      `/ognz/${ognzId}`,
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch ognz list',
      },
    };
  }
}


export async function updateOgnz({ ognzId, ognzDto }: { ognzId: string, ognzDto: OgnzDto }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.put<SuccessResponse<null> | ErrorResponse>(
      `${config.portalApiUrl}/ognz/${ognzId}`,
      ognzDto,
    );

    if (response.data.status === 'success') {
      console.log('Ognz updated successfully');
      return response.data; // 업데이트된 사용자 데이터를 반환
    } else {
      console.log('Update failed with message:', response.data.error.message);
      return response.data; // 에러 데이터를 반환
    }
  } catch (error) {
    console.error('Error updated ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to updated ognz',
      },
    };
  }
}

export async function deleteOgnz(ognzIds: string[]): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await axios.delete<SuccessResponse<null> | ErrorResponse>(
      `/ognz/delete`,
      {
        data: { id: ognzIds }
      }
    );

    if ((await response.data).status === 'success') {
      console.log('success');
      return response.data; // ognzId를 반환
    } else if ((await response.data).status === 'error') {
      console.log((await response.data));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete ognz',
        },
      };
    } else {
      console.log('else');
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete ognz',
        },
      };
    }
  } catch (error) {
    console.error('Error delete ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete ognz',
      },
    };
  }
}

export async function createOgnz(ognzDto: OgnzDto): Promise<SuccessResponse<OgnzDto>> {
  try {
    const response = await accessTokenPortalApi.post<SuccessResponse<OgnzDto>>(
      `/ognz`,
      ognzDto,
    );

    if (response.data.status === 'success') {
      console.log('Organization created successfully');
      return response.data; // 성공적인 응답 반환
    } else {
      // 서버에서 에러 응답을 받은 경우 예외 발생
      console.error('Server returned an error:', response.data);
      throw response.data; // 에러 응답을 그대로 던짐
    }
  } catch (error) {
    // Axios 에러인지 확인
    console.log('Error in createOgnz:', error);
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        // 서버에서 반환한 에러 응답 처리
        console.error('Server error response:', error.response.data);
        throw error.response.data; // 에러 응답을 그대로 던짐
      } else {
        // 네트워크 오류 또는 기타 Axios 에러
        console.error('Axios error:', error.message);
        throw {
          status: 'error',
          error: {
            code: 'NETWORK_ERROR',
            message: error.message,
          },
        };
      }
    } else {
      // 예상치 못한 에러 처리
      console.error('Unexpected error:', error);
      throw {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'An unexpected error occurred while creating the organization.',
        },
      };
    }
  }
}