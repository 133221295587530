import { createReducer } from 'typesafe-actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { MyPageState } from './types';
import {
  getNeospectraUsagesServiesLeftAsync,
  getMapprimeDashboardAsync,
  getNeospectraProjectsStatsAsync,
  getNeospectraJobsStatsAsync,
  getInfoseedDashboardAsync
} from './actions';

import { AnyAction } from 'redux';

const initialState: MyPageState = {
  // GEO_NIC
  infoseedDashboard: asyncState.initial(),
  // SEMMETA
  neoJobsStat: asyncState.initial(),
  neoProjectsStat: asyncState.initial(),
  neoUsagesServies: asyncState.initial(),
  // MAPPRIME
  mapprimeDashboard: asyncState.initial(),
};

const myPageReducer = createReducer<MyPageState, AnyAction>(initialState)
  .handleAction(
    transformToArray(getInfoseedDashboardAsync),
    createAsyncReducer(getInfoseedDashboardAsync, 'infoseedDashboard')
  )
  .handleAction(
    transformToArray(getNeospectraJobsStatsAsync),
    createAsyncReducer(getNeospectraJobsStatsAsync, 'neoJobsStat')
  )
  .handleAction(
    transformToArray(getNeospectraProjectsStatsAsync),
    createAsyncReducer(getNeospectraProjectsStatsAsync, 'neoProjectsStat')
  )
  .handleAction(
    transformToArray(getNeospectraUsagesServiesLeftAsync),
    createAsyncReducer(getNeospectraUsagesServiesLeftAsync, 'neoUsagesServies')
  )
  .handleAction(
    transformToArray(getMapprimeDashboardAsync),
    createAsyncReducer(getMapprimeDashboardAsync, 'mapprimeDashboard')
  )
  ;

export default myPageReducer;