import axios from "axios";
import { SelectUserCntnLogDto, UserCntnLog } from "../types/CntnDto";
import config from "../config";
import { PotalCommDto } from "../types/PotalCommDto";
import { ErrorResponse, SuccessResponse } from '../types/Response';
import accessTokenPortalApi from "./api";

export async function userCntnLogList(selectLogDto: SelectUserCntnLogDto): Promise<SuccessResponse<UserCntnLog[], PotalCommDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<UserCntnLog[], PotalCommDto> | ErrorResponse>(
      `/cntnlog`,
      {
        params: selectLogDto,
      }
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching cntn:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch cntn list',
      },
    };
  }
}