import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import { useNavigate } from 'react-router-dom';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import Pagination from '../../components/Pagination'
import CheckBoxForm from "../../components/CheckBox";
import StartEndDt from "../../components/StartEndDt";
import '../../styles/components/icon.scss';
import { SearchCommonDto } from '../../types/common/SearchCommonDto';
import { PotalCommDto } from '../../types/PotalCommDto';
import { getCodeGrpListAsync , getCodeListAsync , deleteCodeGrpAsync , deleteCodeAsync } from '../../modules/siteMng';

import CreateCodeGrpModal from './CreateCodeGrpModal';
import UpdateCodeGrpModal from './UpdateCodeGrpModal';

import CreateCodeModal from './CreateCodeModal';
import UpdateCodeModal from './UpdateCodeModal';



const CodeGrp: FC = () => {

    // 결과값 리턴
    const dispatch = useDispatch();

    const codeGrpList   = useSelector((state:RootState) => state.siteMng.codeGrpList);
    const codeList      = useSelector((state:RootState) => state.siteMng.codeList);
    const codeGrpDelete = useSelector((state:RootState) => state.siteMng.codeGrpDelete);
    const codeDelete    = useSelector((state:RootState) => state.siteMng.codeDelete);

    const navigate = useNavigate();

    // 변수 선언
    const [createGrpModelShow  , setCreateGrpModelShow]  = useState(false);
    const [updateGrpModelShow  , setUpdateGrpModelShow]  = useState(false);
    const [createCodeModelShow , setCreateCodeModelShow] = useState(false);
    const [updateCodeModelShow , setUpdateCodeModelShow] = useState(false);

    const [searchParams, setFormData] = useState<SearchCommonDto>({
        searchKeywordType : "RRP_CD"
      , currentPageNo : 1
      , pageUnit : 10
    });

    // 초기 접근시 조회
    useEffect(() => {
        onSearch(1);
    },[]);

    // SELECT BOX 변경시 
    const selecthandleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // INPUT BOX 변경시 
    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    // 초기화 버튼 클릭시
    const onSearchReset = async () => {  
        setFormData({
            searchKeywordType : "RRP_CD"
          , searchKeyword : ""
          , pageUnit : 10
        });
    }

    // 조회 버튼 클릭시
    const onSearch = async (pageNo:number) => {  

        const fetchData = async () => {
            await setFormData({...searchParams , currentPageNo: pageNo,cdGrpId:"",cmCdId:"" });
            
            await dispatch(getCodeGrpListAsync.request({ ...searchParams, currentPageNo: pageNo,cdGrpId:"",cmCdId:"" }));
        };

        fetchData();
    }

    // 조회 버튼 클릭시
    const onCodeList = async (cdGrpId:string) => {  
       
        const fetchData = async () => {
            
            await setFormData({...searchParams , cdGrpId: cdGrpId,cmCdId:"" });

            await dispatch(getCodeListAsync.request({...searchParams , cdGrpId: cdGrpId }));
        };

        fetchData();
    }

    /* 공통 그룹코드 관리  */
    const handleGrpModalSaveShow = async () => {
        setCreateGrpModelShow(true);
    };

    const handleGrpModalUpdShow = async () => {
        if (!searchParams.cdGrpId) {
            alert("그룹코드를 선택해 주십시오.");
            return;
        }
        setUpdateGrpModelShow(true)
    };

    const createGrpModelClose = (refresh:string) => {
        setCreateGrpModelShow(false);

        if (refresh == "Y") {
            onSearch(1);
        }
    }

    const updateGrpModelClose = (refresh:string) => {
        setUpdateGrpModelShow(false);
        
        if (refresh == "Y") {
            onSearch(searchParams.currentPageNo!);
        }
    }

    const onGrpDelete = async () => {
        if (!searchParams.cdGrpId) {
            alert("그룹코드를 선택해 주십시오.");
            return;
        }

        if (window.confirm("삭제하시겠습니까?")) {
            dispatch(deleteCodeGrpAsync.request(searchParams.cdGrpId));
        }
    }

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (codeGrpDelete && codeGrpDelete.data && searchParams.cdGrpId) {
            const status = codeGrpDelete.data.status;
    
            if (status == "error") {
                alert(codeGrpDelete.data.error.message);
            } else if (status == "success") {
                alert("삭제 되었습니다.");
                onSearch(1);
            }
        }
    },[codeGrpDelete]);

    /* 공통 코드 관리  */
    const onCode  = async (cmCdId:string) => {
        await setFormData({...searchParams ,cmCdId:cmCdId }); 
    }

    const handleCodeModalSaveShow = async () => {
        if (!searchParams.cdGrpId) {
            alert("그룹코드를 선택해 주십시오.");
            return;
        }

        setCreateCodeModelShow(true);
    };

    const handleCodeModalUpdShow = async () => {
        if (!searchParams.cdGrpId) {
            alert("그룹코드를 선택해 주십시오.");
            return;
        }

        if (!searchParams.cmCdId) {
            alert("코드를 선택해 주십시오.");
            return;
        }
        setUpdateCodeModelShow(true)
    };

    const createCodeModelClose = (refresh:string) => {
        setCreateCodeModelShow(false);

        if (refresh == "Y") {
            onCodeList(searchParams.cdGrpId!);
        }
    }

    const updateCodeModelClose = (refresh:string) => {
        setUpdateCodeModelShow(false);
        
        if (refresh == "Y") {
            onCodeList(searchParams.cdGrpId!);
        }
    }

    const onCodeDelete = async () => {
        if (!searchParams.cdGrpId) {
            alert("그룹코드를 선택해 주십시오.");
            return;
        }

        if (window.confirm("삭제하시겠습니까?")) {
            const codeGrp = searchParams.cdGrpId + "_" + searchParams.cmCdId;

            dispatch(deleteCodeAsync.request(codeGrp));
        }
    }

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (codeDelete && codeDelete.data && searchParams.cdGrpId && searchParams.cmCdId) {
            
            const status = codeDelete.data.status;
    
            if (status == "error") {
                alert(codeDelete.data.error.message);
            } else if (status == "success") {
                alert("삭제 되었습니다.");
                onCodeList(searchParams.cdGrpId!);
            }
        }
    },[codeDelete]);

   

    

    // 조회 목록 HTML 생성
    var resultList = [];
    var totalCnt = 0;
    var pageUnit = 10;
    var currentPageNo = 1;
    //console.log('리턴 결과:', JSON.stringify(codeGrpList, null, 2));
    if (codeGrpList && codeGrpList.loading ) {
        resultList.push(
            <tr >
                <td colSpan={2} style={{ textAlign: "center" }}>loging...</td>
            </tr>)
    } else {
        if (codeGrpList.data && codeGrpList.data.meta) {
            totalCnt = Number(codeGrpList.data.meta.totalCnt);
            pageUnit = codeGrpList.data.meta.pageUnit;
            currentPageNo = codeGrpList.data.meta.currentPageNo;
        }
        
        if (codeGrpList.data && codeGrpList.data.data) {
    
            for (var i=0;i<codeGrpList.data.data.length;i++) {
                var result = codeGrpList.data.data[i];
                var listNo = totalCnt - (pageUnit * (currentPageNo -1) + i);
                var bgColor = "#ffffff";

                if (searchParams.cdGrpId == result.cdGrpId) {
                    bgColor = "#dedbde";
                }

                resultList.push(
                    
                    <tr key={"tr_"+listNo}  >
                        <td style={{fontSize:"12px",backgroundColor:bgColor}}>
                            <button key={"code_"+result.cdGrpId} className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' value={result.cdGrpId} onClick={(e) => onCodeList(e.currentTarget.value)}>
                                {result.cdGrpId}
                            </button>
                        </td>   
                        <td style={{fontSize:"12px",backgroundColor:bgColor}}>{result.cdGrpNm}</td>
                    </tr>)
            }
        }

         // console.log('리턴 결과:', JSON.stringify(codeGrpList, null, 2));
    }


    var resultCodeList = [];
    var listCodeTotalCnt = 0;

    if (codeList && codeList.loading ) {
        resultCodeList.push(
            <tr >
                <td colSpan={5} style={{ textAlign: "center" }}>loging...</td>
            </tr>)
    } else {
        if (codeList.data && codeList.data.data && searchParams.cdGrpId) {
            listCodeTotalCnt = Number(codeList.data.data.length);

            for (var i=0;i<codeList.data.data.length;i++) {
                var result = codeList.data.data[i];

                var bgColor = "#ffffff";

                if (searchParams.cmCdId == result.cmCdId) {
                    bgColor = "#dedbde";
                }

                resultCodeList.push(
                    <tr key={"tr_code_"+i}  >
                        <td style={{fontSize:"12px",width:"200px",backgroundColor:bgColor}}>
                            <button key={"code_"+result.cmCdId} className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' value={result.cmCdId} onClick={(e) => onCode(e.currentTarget.value)}>
                                {result.cmCdId}
                            </button>
                        </td>    
                        <td style={{fontSize:"12px",backgroundColor:bgColor}}>{result.cdVl}</td>
                        <td style={{fontSize:"12px",textAlign:"center",backgroundColor:bgColor}}>{result.cdOrr}</td>
                        <td style={{fontSize:"12px",backgroundColor:bgColor}}>{result.etcCn1}</td>
                        <td style={{fontSize:"12px",backgroundColor:bgColor}}>{result.cdDesc}</td>
                    </tr>)
            }
        }

        if (resultCodeList.length == 0) {
            resultCodeList.push(
                <tr >
                    <td colSpan={5} style={{ textAlign: "center" }}>조회된 결과가 없습니다.</td>
                </tr>)
        }

         // console.log('리턴 결과:', JSON.stringify(codeGrpList, null, 2));
    }

    

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='공통코드 조회'>
                    {/* Content */}

                    <div className='row'>
                        <div className='dash-wrap-cont dash-info-wrap'>
                            <div className="row mb-3">
                                <div className="col-1 col-form-label">
                                    검색어
                                </div>  
                                <div className="col-3">
                                    <select className="form-select form-select-sm" id="searchKeywordType" name="searchKeywordType" value={searchParams.searchKeywordType} aria-label="Default select example" onChange={selecthandleChange}>
                                        <option value="RRP_CD">그룹 코드</option>
                                        <option value="RRP_NM">그룹 코드명</option>
                                        <option value="CD">코드</option>
                                        <option value="CD_NM">코드명</option>
                                    </select>
                                </div>
                                <div className="col-8">
                                    <input className="form-control form-control-sm" type="text" aria-label=".form-control-sm example" id="searchKeyword" name="searchKeyword" value={searchParams.searchKeyword} onChange={inputHandleChange}/>
                                </div>
                            </div>
                            
                            <div className="d-flex justify-content-center gap-2 mt-3">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={onSearchReset}>초기화</button>
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => onSearch(1)}>검색</button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="display-count mb-1 d-flex justify-content-between" style={{gap: "30px", padding:"0px" }}>
                            <div className="dash-wrap-cont" style={{width:"60%"}}>
                                <div className="display-count mb-1 d-flex justify-content-between" >
                                    <span>총 게시물 : {totalCnt}</span>
                                    <div style={{gap : "5px",display:"flex"}}>
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={handleGrpModalSaveShow}>등 록</button>
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={handleGrpModalUpdShow}>수 정</button>
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={onGrpDelete}>삭 제</button>
                                    </div>
                                </div>
                                <table className="table table-bordered table-hover" >
                                    <colgroup>
                                        <col style={{ width: '200px' }} />
                                        <col style={{ width: 'auto' }} />
                                    </colgroup>
                                    <thead>
                                        <th className='text-center' style={{width:"200px"}}>그룹코드</th>
                                        <th className='text-center'>그룹코드명</th>
                                    </thead>
                                    <tbody>
                                        {resultList}
                                    </tbody>
                                </table>
                                { codeGrpList.data && codeGrpList.data.meta  ? ( 
                                        <Pagination  pageingDto={codeGrpList.data.meta} onClick={onSearch} />        
                                    ) : ( <span> </span> )
                                }

                            </div>
                            <div className="dash-wrap-cont" style={{width:"90%"}}>
                                <div className="display-count mb-1 d-flex justify-content-between" >
                                    <span>총 게시물 : {listCodeTotalCnt}</span>
                                    <div style={{gap : "5px",display:"flex"}}>
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={handleCodeModalSaveShow}>등 록</button>
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={handleCodeModalUpdShow}>수 정</button>
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={onCodeDelete}>삭 제</button>
                                    </div>
                                </div>
                                <table className="table table-bordered table-hover">
                                    <colgroup>
                                        <col style={{ width: '100px' }} />
                                        <col style={{ width: '200px' }} />
                                        <col style={{ width: '80px' }} />
                                        <col style={{ width: '120px' }} />
                                        <col style={{ width: 'auto' }} />
                                    </colgroup>
                                    <thead>
                                        <th className='text-center'>코드</th>
                                        <th className='text-center'>코드명</th>
                                        <th className='text-center'>순서</th>
                                        <th className='text-center'> 기타1</th>
                                        <th className='text-center'>설명</th>
                                    </thead>
                                    <tbody>
                                       {resultCodeList}
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>

                        
                    </div>
                </ContentLayout> 

                <CreateCodeGrpModal openModel={createGrpModelShow} handleModalClose={createGrpModelClose} />

                <UpdateCodeGrpModal openModel={updateGrpModelShow} cdGrpId={searchParams.cdGrpId!} handleModalClose={updateGrpModelClose} />

                <CreateCodeModal openModel={createCodeModelShow}   cdGrpId={searchParams.cdGrpId!} handleModalClose={createCodeModelClose} />

                <UpdateCodeModal openModel={updateCodeModelShow} cdGrpId={searchParams.cdGrpId!} cmCdId={searchParams.cmCdId!} handleModalClose={updateCodeModelClose} />

                
            </div>
        </div>
    )
};

export default CodeGrp;
