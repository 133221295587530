import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import { useNavigate } from 'react-router-dom';
import { UpdateContactUsDto } from '../../types/support/UpdateContactUsDto';
import { getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync } from '../../modules/support';
import { getFileDownLoad }  from '../../api/common';
import Table from 'react-bootstrap/Table';

const ContactUsDetail:FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 결과 리턴값 설정
    const contactUsDetail  = useSelector((state:RootState) => state.support.data);
    const updateResult     = useSelector((state:RootState) => state.support.contactUsUpdate);
    const deleteResult     = useSelector((state:RootState) => state.support.contactUsDelete);

    // 변수 선언
    const [inqSn, setInqSn] = useState<number>(0);
    const [action, setAction] = useState<string>('');

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더합니다.
    const day = String(today.getDate()).padStart(2, '0');

    const [contactUs, setContactUs] = useState<any>({
        inqTypeCd: ""
      , inqTtl: ""
      , inqCn: ""
      , rlsStngCd:""
      , inqTypeNm:""
      , regUserNm : ""
      , regDt :  ""
      , FileList : []
    }); 
    
    // 화면 접근시 처리 로직 ( 수정 으로 접근시 조회)
    useEffect(() => {
        const fetchSearchData = async () => {
            const currentPath = window.location.pathname.split('/'); // 경로
            const userName  = sessionStorage.getItem('userName');
            if (!userName) {
                alert("로그인 후 이용이 가능합니다.");
                navigate("/login");
            }
            
            const inqSn = currentPath[currentPath.length-1];
            
            setInqSn(Number(inqSn));
            
            await dispatch(getContactUsAsync.request(Number(inqSn)));
        };

        fetchSearchData();

      },[]);

      // 화면 접근시 처리 로직 ( 수정 으로 접근시 조회)
    useEffect(() => {
        console.log('contactUsDetail 전송 결과:', JSON.stringify(contactUsDetail, null, 2));

        if (contactUsDetail && contactUsDetail.data && contactUsDetail.data.data) {
            setContactUs((pre:any) =>contactUsDetail.data.data);
        }

      },[contactUsDetail]);

      // input,textarea 객체 변경시 
    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setContactUs((prev:any) => ({ ...prev, [name]: value }));
    };


    // 취소 버튼 클릭시
    const oncancel = async () => {
        navigate(-1);
    }

    const onUpdate = async () => {
        navigate("/contactUsWrite/"+inqSn);
    }

    // 파일 다운로드 버튼 클릭시
    const handleFileDownClick = (inqSn : number , atflSn : number , event: React.MouseEvent<HTMLButtonElement> ) => {
        // e.target.attributes();
 
        getFileDownLoad('contactUs' , inqSn , atflSn );
    }

    // 삭제 버튼 클릭시
    const onDel = async () => {
        const confirmDelete = window.confirm("정말로 삭제하시겠습니까?");
        if (confirmDelete) {
             await dispatch(deleteContactUsAsync.request(inqSn));
             await setAction("DELETE");
        } 
    };

    // 삭제 후처리
    useEffect(() => {
        if (action == "DELETE") {
            if (deleteResult && deleteResult.data) {
                const status = deleteResult.data.status;
        
                if (status == "error") {
                    alert(deleteResult.data.error.message);
                } else if (status == "success") {
                    alert("삭제 되었습니다.");
                    navigate("/contactUsList");
                }
            }
        }
        
    },[deleteResult]);

    // 삭제 버튼 클릭시
    const onReply = async () => {
        if (!contactUs.rspnsCn) {
            alert('답변을 입력해 주십시오.');
            return;
        }

        dispatch(putContactUsAsync.request(contactUs));
        await setAction("REPLY"); 
    };

    // 수정 후처리
    useEffect(() => {
        if (action == "REPLY") {
            if (updateResult && updateResult.data) {
                const status = updateResult.data.status;
        
                if (status == "error") {
                    alert(updateResult.data.error.message);
                } else if (status == "success") {
                    alert("답변이 등록되었습니다.");
                    navigate(0);
                }
            }
        }
        
    },[updateResult]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='문의사항 조회'>
                    {/* Content */}
            <div className="row">
                <div className="container">
                    <div className="table table-write">
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{width: '150px' }} />
                                <col style={{width: 'auto' }} />
                                <col style={{width: '150px' }} />
                                <col style={{width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr> 
                                    <th colSpan={4} style={{backgroundColor:"#dedbde"}}><b>{ contactUs.inqTtl }</b></th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>작성자</th>
                                    <td>{contactUs.regUserNm}</td>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>작성일</th>
                                    <td>{contactUs.regDt}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>문의 항목</th>
                                    <td colSpan={3}>
                                        { contactUs.inqTypeNm }
                                    </td>
                                </tr>
                                
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>내용</th>
                                    <td colSpan={3}>
                                        { contactUs.inqCn }
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>첨부파일</th>
                                    
                                        {contactUs.atflOrgnlNm ? (
                                            <td colSpan={3}>
                                            {contactUs.atflOrgnlNm}
                                            <button type='button' title='파일 다운로드' style={{marginLeft:"10px"}} onClick={(e)=>{handleFileDownClick( inqSn , 0 , e )}}><i className='icon--download' aria-hidden='true' ></i>다운로드</button>    
                                            </td>
                                        ) : (
                                            <td colSpan={3}></td>
                                        )}
                                </tr>
                                
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>공개 설정</th>
                                    <td colSpan={3}>
                                        { contactUs.rlsStngNm }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className="table table-write" style={{marginTop:"30px"}}>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{width: '150px' }} />
                                <col style={{width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr style={{ textAlign: "center"  , verticalAlign: "middle"}}> 
                                    <th colSpan={2} style={{backgroundColor:"#737bd9" , color:"#ffffff"}}><b>공공 SAAS 답변</b></th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>작성자</th>
                                    <td style={{width:"85%"}}>{contactUs.rspnsCmptnUserNm}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>답변</th>
                                    <td >
                                    <textarea name="rspnsCn" id="rspnsCn" value={contactUs.rspnsCn} className='form-control block' title='답변 내용을 입력' placeholder='답변 내용을 입력하세요.' onChange={inputHandleChange}></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}>답변일자</th>
                                    <td >
                                        { contactUs.rspnsCmptnDt }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='board-foot-buttons' style={{display:"flex" , justifyContent: 'flex-end',gap:"10px"}}>
                        <button type="button" className="btn btn-secondary  m-1"  onClick={oncancel}>목록</button>
                        <button type="button" className="btn btn btn-primary m-1" onClick={onReply}>답변하기</button>
                        <button type="button" className="btn btn btn-primary m-1" onClick={onDel}>삭제하기</button>
                        <button type="button" className="btn btn-secondary  m-1"  onClick={onUpdate} style={{display:"none"}}>수정하기</button>
                    </div>

                </div>
            </div>
            
            </ContentLayout>
            </div>
        </div>
    )
};

export default ContactUsDetail;