import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { FIND_PRDCT_ITEM_LIST, FIND_PRDCT_LIST, findPrdctItemListAsync, findPrdctListAsync, GET_BUY_USE_LIST, GET_MAPP_DASH, getBuyUseListAsync, UPDATE_PRDCT, createPrdctAsync as updatePrdctAsync } from './actions';
import { updatePrdct, findPrdctItemList, findPrdctList, getBuyUseList } from '../../api/prdct';


const findPrdctListSaga = createAsyncSaga(findPrdctListAsync, findPrdctList);
const findPrdctItemListSaga = createAsyncSaga(findPrdctItemListAsync, findPrdctItemList);
const updatePrdctSaga = createAsyncSaga(updatePrdctAsync, updatePrdct);
const getBuyUseListSaga = createAsyncSaga(getBuyUseListAsync, getBuyUseList);

export function* prdtcSaga() {
  yield takeEvery(FIND_PRDCT_LIST, findPrdctListSaga);
  yield takeEvery(FIND_PRDCT_ITEM_LIST, findPrdctItemListSaga);
  yield takeEvery(UPDATE_PRDCT, updatePrdctSaga);
  yield takeEvery(GET_BUY_USE_LIST , getBuyUseListSaga);
}
