import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { getCodeGrpAsync , putCodeGrpAsync } from '../../modules/siteMng';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { UpdateCodeGrpDto } from '../../types/common/UpdateCodeGrpDto';

interface props {
    openModel : boolean;
    cdGrpId : string;
    handleModalClose: (refreshYn :string) => void; // onChange prop 추가
}

const UpdateCodeGrpModal:FC<props> = ({ openModel , cdGrpId , handleModalClose }) => {

    const dispatch = useDispatch();

    const codeGrp        = useSelector((state:RootState) => state.siteMng.codeGrp);
    const codeGrpUpdate  = useSelector((state:RootState) => state.siteMng.codeGrpUpdate);
    
    const [updateCodeGrpParams, setUpdateCodeGrp] = useState<UpdateCodeGrpDto>({
        cdGrpNm    : ""
      , cdGrpExpln : ""
  });
    
    const inputHandleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateCodeGrp((prev) => ({ ...prev, [name]: value}));
    };

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (openModel && cdGrpId) {
            dispatch(getCodeGrpAsync.request(cdGrpId));
        }
    },[openModel]);

    // 조회 결과 적용
    useEffect(() => {
        if (codeGrp?.data?.data) {
            setUpdateCodeGrp((prev) => ({ ...prev 
                , cdGrpNm: codeGrp?.data?.data.cdGrpNm
                , cdGrpExpln : codeGrp?.data?.data.cdGrpExpln
            }));
        }
        
    },[codeGrp]);


    const onGrpSave = () => {
        
        if (!updateCodeGrpParams.cdGrpNm) {
            alert("그룹코드명를 입력해 주십시오.");
            return;
        }

        dispatch(putCodeGrpAsync.request({
            cdGrpId : cdGrpId 
          , updateCodeGrpDto : updateCodeGrpParams
        }
        ));
    }

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (codeGrpUpdate && codeGrpUpdate.data  && cdGrpId) {
            const status = codeGrpUpdate.data.status;
    
            if (status == "error") {
                alert(codeGrpUpdate.data.error.message);
            } else if (status == "success") {
                alert("수정 되었습니다.");
                returnModalClose("Y");
            }
        }
    },[codeGrpUpdate]);

    const returnModalClose = (refresh:string) => {
        handleModalClose(refresh);
    }

    return (
        <Modal show={openModel} onHide={() => returnModalClose('N')} style={{verticalAlign:"moddle" , top:"25%"}}>
            <Modal.Header closeButton>
                <Modal.Title>그룹 공통코드 수정</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className='dash-ti mb-2'>필수 정보</h3>
                <Table bordered size="sm">
                    <colgroup>
                        <col style={{ width: '120px' }} />
                        <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                        <tr>    
                            <th>그룹코드</th>
                            <td style={{ paddingLeft: '10px' }}>
                            {cdGrpId}
                            </td>
                        </tr>
                        <tr>
                            <th>그룹코드명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeGrpParams.cdGrpNm}  aria-label=".form-control-sm example" id="cdGrpNm" name="cdGrpNm" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>설명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeGrpParams.cdGrpExpln}  aria-label=".form-control-sm example" id="cdGrpExpln" name="cdGrpExpln" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onGrpSave}>
                    저장
                </Button>
                <Button variant="secondary" onClick={() => returnModalClose('N')}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateCodeGrpModal;