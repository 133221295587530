import { createReducer } from 'typesafe-actions';
import { SiteMngState } from './types';
import { getCodeGrpListAsync , getCodeGrpAsync , postCodeGrpAsync , putCodeGrpAsync , deleteCodeGrpAsync 
       , getCodeListAsync , getCodeAsync , postCodeAsync , putCodeAsync , deleteCodeAsync   } from './actions';

import { AnyAction } from 'redux';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: SiteMngState = {
  data: asyncState.initial(),

  codeGrpList   : asyncState.initial(),
  codeGrp       : asyncState.initial(),
  codeGrpInsert : asyncState.initial(),
  codeGrpUpdate : asyncState.initial(),
  codeGrpDelete : asyncState.initial(),

  codeList   : asyncState.initial(),
  code       : asyncState.initial(),
  codeInsert : asyncState.initial(),
  codeUpdate : asyncState.initial(),
  codeDelete : asyncState.initial(),

};
const siteMngReducer = createReducer<SiteMngState, AnyAction>(initialState)
  .handleAction(
    transformToArray(getCodeGrpListAsync),
    createAsyncReducer(getCodeGrpListAsync, 'codeGrpList')
  )
  .handleAction(
    transformToArray(getCodeGrpAsync),
    createAsyncReducer(getCodeGrpAsync, 'codeGrp')
  )
  .handleAction(
    transformToArray(postCodeGrpAsync),
    createAsyncReducer(postCodeGrpAsync, 'codeGrpInsert')
  )
  .handleAction(
    transformToArray(putCodeGrpAsync),
    createAsyncReducer(putCodeGrpAsync, 'codeGrpUpdate')
  )
  .handleAction(
    transformToArray(deleteCodeGrpAsync),
    createAsyncReducer(deleteCodeGrpAsync, 'codeGrpDelete')
  )
  .handleAction(
    transformToArray(getCodeListAsync),
    createAsyncReducer(getCodeListAsync, 'codeList')
  )
  .handleAction(
    transformToArray(getCodeAsync),
    createAsyncReducer(getCodeAsync, 'code')
  )
  .handleAction(
    transformToArray(postCodeAsync),
    createAsyncReducer(postCodeAsync, 'codeInsert')
  )
  .handleAction(
    transformToArray(putCodeAsync),
    createAsyncReducer(putCodeAsync, 'codeUpdate')
  )
  .handleAction(
    transformToArray(deleteCodeAsync),
    createAsyncReducer(deleteCodeAsync, 'codeDelete')
  )
  ;

export default siteMngReducer;