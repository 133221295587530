import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
export const GET_CD_GRP = 'api/GET_CD_GRP';
export const GET_CD_GRP_SUCCESS = 'api/GET_CD_GRP_SUCCESS';
export const GET_CD_GRP_ERROR = 'api/GET_CD_GRP_ERROR';

export const DELETE_FILE = 'api/GET_DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'api/DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'api/DELETE_FILE_ERROR';

export const getCommCdListAsync = createAsyncAction(
  GET_CD_GRP,
  GET_CD_GRP_SUCCESS,
  GET_CD_GRP_ERROR
)<any, any | null , AxiosError>();

export const deleteFileAsync = createAsyncAction(
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR
)<any, any | null , AxiosError>();


