import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

export const GET_MYPAGE_NEO_PROJECT_STATS         = 'api/GET_MYPAGE_NEO_PROJECT_STATS'
export const GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS = 'api/GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS'
export const GET_MYPAGE_NEO_PROJECT_STATS_ERROR   = 'api/GET_MYPAGE_NEO_PROJECT_STATS_ERROR'

export const GET_MYPAGE_NEO_JOBS_STATS         = 'api/GET_MYPAGE_NEO_JOBS_STATS'
export const GET_MYPAGE_NEO_JOBS_STATS_SUCCESS = 'api/GET_MYPAGE_NEO_JOBS_STATS_SUCCESS'
export const GET_MYPAGE_NEO_JOBS_STATS_ERROR   = 'api/GET_MYPAGE_NEO_JOBS_STATS_ERROR'

export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT         = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT'
export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS'
export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR   = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR'

export const GET_MAPPRIME_DASHBOARD         = 'api/GET_MAPPRIME_DASHBOARD'
export const GET_MAPPRIME_DASHBOARD_SUCCESS = 'api/GET_MAPPRIME_DASHBOARD_SUCCESS'
export const GET_MAPPRIME_DASHBOARD_ERROR   = 'api/GET_MAPPRIME_DASHBOARD_ERROR'

export const GET_INFOSEED_DASHBOARD         = 'api/GET_INFOSEED_DASHBOARD'
export const GET_INFOSEED_DASHBOARD_SUCCESS = 'api/GET_INFOSEED_DASHBOARD_SUCCESS'
export const GET_INFOSEED_DASHBOARD_ERROR   = 'api/GET_INFOSEED_DASHBOARD_ERROR'

export const getNeospectraProjectsStatsAsync = createAsyncAction(
  GET_MYPAGE_NEO_PROJECT_STATS,
  GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS,
  GET_MYPAGE_NEO_PROJECT_STATS_ERROR
)<any, any, AxiosError>();

export const getNeospectraJobsStatsAsync = createAsyncAction(
  GET_MYPAGE_NEO_JOBS_STATS,
  GET_MYPAGE_NEO_JOBS_STATS_SUCCESS,
  GET_MYPAGE_NEO_JOBS_STATS_ERROR
)<any, any, AxiosError>();

export const getNeospectraUsagesServiesLeftAsync = createAsyncAction(
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT,
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS,
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR
)<number, any[] | null, AxiosError>();

export const getMapprimeDashboardAsync = createAsyncAction(
  GET_MAPPRIME_DASHBOARD,
  GET_MAPPRIME_DASHBOARD_SUCCESS,
  GET_MAPPRIME_DASHBOARD_ERROR
)<any, any[] | null, AxiosError>();

export const getInfoseedDashboardAsync = createAsyncAction(
  GET_INFOSEED_DASHBOARD,
  GET_INFOSEED_DASHBOARD_SUCCESS,
  GET_INFOSEED_DASHBOARD_ERROR
)<{ognzId:string, ognzUserGrpSn:number}, any[] | null, AxiosError>();