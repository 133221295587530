import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { SearchCommonDto } from '../../types/common/SearchCommonDto';
import { CreateCodeGrpDto } from '../../types/common/CreateCodeGrpDto';
import { UpdateCodeGrpDto } from '../../types/common/UpdateCodeGrpDto';
import { CreateCodeDto } from '../../types/common/CreateCodeDto';
import { UpdateCodeDto } from '../../types/common/UpdateCodeDto';

export const GET_SITEMNG_CODEGRP               = 'api/GET_SITEMNG_CODEGRP';
export const GET_SITEMNG_CODEGRP_SUCCESS       = 'api/GET_SITEMNG_CODEGRP_SUCCESS';
export const GET_SITEMNG_CODEGRP_ERROR         = 'api/GET_SITEMNG_CODEGRP_ERROR';

export const GET_SITEMNG_CODEGRP_BY_ID         = 'api/GET_SITEMNG_CODEGRP_BY_ID';
export const GET_SITEMNG_CODEGRP_BY_ID_SUCCESS = 'api/GET_SITEMNG_CODEGRP_BY_ID_SUCCESS';
export const GET_SITEMNG_CODEGRP_BY_ID_ERROR   = 'api/GET_SITEMNG_CODEGRP_BY_ID_ERROR';

export const POST_SITEMNG_CODEGRP           = 'api/POST_SITEMNG_CODEGRP';
export const POST_SITEMNG_CODEGRP_SUCCESS   = 'api/POST_SITEMNG_CODEGRP_SUCCESS';
export const POST_SITEMNG_CODEGRP_ERROR     = 'api/POST_SITEMNG_CODEGRP_ERROR';

export const PUT_SITEMNG_CODEGRP            = 'api/PUT_SITEMNG_CODEGRP';
export const PUT_SITEMNG_CODEGRP_SUCCESS    = 'api/PUT_SITEMNG_CODEGRP_SUCCESS';
export const PUT_SITEMNG_CODEGRP_ERROR      = 'api/PUT_SITEMNG_CODEGRP_ERROR';

export const DELETE_SITEMNG_CODEGRP         = 'api/DELETE_SITEMNG_CODEGRP';
export const DELETE_SITEMNG_CODEGRP_SUCCESS = 'api/DELETE_SITEMNG_CODEGRP_SUCCESS';
export const DELETE_SITEMNG_CODEGRP_ERROR   = 'api/DELETE_SITEMNG_CODEGRP_ERROR';

export const GET_SITEMNG_CODE               = 'api/GET_SITEMNG_CODE';
export const GET_SITEMNG_CODE_SUCCESS       = 'api/GET_SITEMNG_CODE_SUCCESS';
export const GET_SITEMNG_CODE_ERROR         = 'api/GET_SITEMNG_CODE_ERROR';

export const GET_SITEMNG_CODE_BY_ID         = 'api/GET_SITEMNG_CODE_BY_ID';
export const GET_SITEMNG_CODE_BY_ID_SUCCESS = 'api/GET_SITEMNG_CODE_BY_ID_SUCCESS';
export const GET_SITEMNG_CODE_BY_ID_ERROR   = 'api/GET_SITEMNG_CODE_BY_ID_ERROR';

export const POST_SITEMNG_CODE           = 'api/POST_SITEMNG_CODE';
export const POST_SITEMNG_CODE_SUCCESS   = 'api/POST_SITEMNG_CODE_SUCCESS';
export const POST_SITEMNG_CODE_ERROR     = 'api/POST_SITEMNG_CODE_ERROR';

export const PUT_SITEMNG_CODE            = 'api/PUT_SITEMNG_CODE';
export const PUT_SITEMNG_CODE_SUCCESS    = 'api/PUT_SITEMNG_CODE_SUCCESS';
export const PUT_SITEMNG_CODE_ERROR      = 'api/PUT_SITEMNG_CODE_ERROR';

export const DELETE_SITEMNG_CODE         = 'api/DELETE_SITEMNG_CODE';
export const DELETE_SITEMNG_CODE_SUCCESS = 'api/DELETE_SITEMNG_CODE_SUCCESS';
export const DELETE_SITEMNG_CODE_ERROR   = 'api/DELETE_SITEMNG_CODE_ERROR';


export const getCodeGrpListAsync = createAsyncAction(
  GET_SITEMNG_CODEGRP,
  GET_SITEMNG_CODEGRP_SUCCESS,
  GET_SITEMNG_CODEGRP_ERROR
)<SearchCommonDto, any | null , AxiosError>();

export const getCodeGrpAsync = createAsyncAction(
  GET_SITEMNG_CODEGRP_BY_ID,
  GET_SITEMNG_CODEGRP_BY_ID_SUCCESS,
  GET_SITEMNG_CODEGRP_ERROR
)<string, any | null , AxiosError>();

export const postCodeGrpAsync = createAsyncAction(
  POST_SITEMNG_CODEGRP,
  POST_SITEMNG_CODEGRP_SUCCESS,
  POST_SITEMNG_CODEGRP_ERROR
)<CreateCodeGrpDto, any | null , AxiosError>();

export const putCodeGrpAsync = createAsyncAction(
  PUT_SITEMNG_CODEGRP,
  PUT_SITEMNG_CODEGRP_SUCCESS,
  PUT_SITEMNG_CODEGRP_ERROR
)<{cdGrpId : string , updateCodeGrpDto : UpdateCodeGrpDto}, any | null , AxiosError>();

export const deleteCodeGrpAsync = createAsyncAction(
  DELETE_SITEMNG_CODEGRP,
  DELETE_SITEMNG_CODEGRP_SUCCESS,
  DELETE_SITEMNG_CODEGRP_ERROR
)<string, any | null , AxiosError>();

export const getCodeListAsync = createAsyncAction(
  GET_SITEMNG_CODE,
  GET_SITEMNG_CODE_SUCCESS,
  GET_SITEMNG_CODE_ERROR
)<SearchCommonDto, any | null , AxiosError>();

export const getCodeAsync = createAsyncAction(
  GET_SITEMNG_CODE_BY_ID,
  GET_SITEMNG_CODE_BY_ID_SUCCESS,
  GET_SITEMNG_CODE_ERROR
)<string, any | null , AxiosError>();

export const postCodeAsync = createAsyncAction(
  POST_SITEMNG_CODE,
  POST_SITEMNG_CODE_SUCCESS,
  POST_SITEMNG_CODE_ERROR
)<CreateCodeDto, any | null , AxiosError>();

export const putCodeAsync = createAsyncAction(
  PUT_SITEMNG_CODE,
  PUT_SITEMNG_CODE_SUCCESS,
  PUT_SITEMNG_CODE_ERROR
)<{codeGrp : string , updateCodeDto : UpdateCodeDto} , any | null , AxiosError>();

export const deleteCodeAsync = createAsyncAction(
  DELETE_SITEMNG_CODE,
  DELETE_SITEMNG_CODE_SUCCESS,
  DELETE_SITEMNG_CODE_ERROR
)<string, any | null , AxiosError>();


