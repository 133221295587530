import { FC, useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { deleteUserAsync, findUserAsync, updateUserAsync } from "../../modules/user";
import { ProfileDto, SelectUserDto, UserDto } from "../../types/UserDto";
import { format } from 'date-fns';
import Pagination from "../../components/Pagination";
import { getCommCdListAsync } from "../../modules/common";
import { SelectUserGroupDto } from "../../types/UserGroupDto";
import { deleteUserGroupMemberAsync, findUserGroupListAsync, findUserGroupMemberListAsync, updateGroupUserRoleAsync, updateUserGroupMemberAsync } from "../../modules/usergroup";
import { Link, useNavigate } from "react-router-dom";

const UserGroupList: FC = () => {
    const navigate = useNavigate();
    const [profileDto, setProfileDto] = useState<ProfileDto>({
        user: {
            userId: "",
            userName: "",
            email: "",
            emaildomain: "",
            password: "",
            rePassword: "",
            roleCd: "",
            organizationId: "",
            ognzName: "",
            useYn: "",
            regUserId: "",
            regDt: "",
            modUserId: "",
        },
        products: []
    });
    const [selectedPeriod, setSelectedPeriod] = useState<string>("all");
    const dispatch = useDispatch();
    const comboInfo = useSelector((state: RootState) => state.common.data);
    const [ognzUserGrpSn, setOgnzUserGrpSn] = useState(0);
    const [popupUserName, setPopupUserName] = useState("");
    const userGroupList = useSelector((state: RootState) => state.usergroup.userGroupList);
    const userGroupMemberList = useSelector((state: RootState) => state.usergroup.userGroupMemberList);
    const selectedUser = useSelector((state: RootState) => state.user.selectedUser);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);               // 선택된 사용자들
    const [selectedModalUsers, setSelectedModalUsers] = useState<string[]>([]);   // 모달 선택된 사용자들
    const [modalUserList, setModalUserList] = useState<SelectUserGroupDto[]>([]);
    const [userInfoShow, setUserInfoShow] = useState(false);
    const [userCountShow, setUserCountShow] = useState(false);
    const [searchParams, setSearchParams] = useState<SelectUserDto>({
        searchType: 'user_nm', // 검색어 조건
        searchKeyword: '',
        currentPageNo: 1,
        searchOrder: '',
        fromDate: '',   // 시작일자
        toDate: '', // 종료일자
    });
    const [searchGroupParams, setSearchGroupParams] = useState<SelectUserDto>({
        currentPageNo: 1,
    });

    // 현재 날짜를 가져오기 위한 함수
    const getCurrentDate = () => format(new Date(), 'yyyy-MM-dd');

    // 조회기간 버튼 클릭 핸들러
    // 기간 설정 핸들러
    const handlePeriodChange = (period: string) => {
        let fromDate = '', toDate = getCurrentDate();
        setSelectedPeriod(period);
        switch (period) {
            case 'year':
                fromDate = format(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'sixMonths':
                fromDate = format(new Date(Date.now() - 183 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'oneMonth':
                fromDate = format(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'oneWeek':
                fromDate = format(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            default:
                fromDate = '';
                toDate = '';
        }

        // 상태 업데이트
        setSearchParams(prev => ({
            ...prev,
            fromDate,
            toDate,
        }));
    };

    // 초기화 버튼 클릭 핸들러
    const handleReset = () => {
        // 검색 조건 초기화
        setSearchParams({
            searchType: '',
            searchKeyword: '',
            currentPageNo: 1,
            searchOrder: '',
            userRoleCd: '',
            fromDate: '',
            toDate: '',
        });
    };

    // 검색어와 권한 변경 핸들러
    const handleInputChange = (field: keyof SelectUserDto, value: string) => {
        setSearchParams(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const [isdropOpen, setIsdropOpen] = useState(false);

    const handleUserInfoClose = () => setUserInfoShow(false);
    const handleUserCountClose = () => {
        setUserCountShow(false);
        setModalUserList([]);
    };
    const handleMoveUserGrpShow = async (user: SelectUserGroupDto) => {
        navigate('/dashboard/', { state: { ognzId: user.ognzId, ognzGrpSn: user.ognzGrpSn } });
    };

    // 소속 회원 모달 창 보기
    const handleUserCountShow = async (ognzGrpSn: number, userName: string) => {
        setSelectedModalUsers([]);
        dispatch(findUserGroupMemberListAsync.request({ ognzGrpSn: ognzGrpSn, selectUserDto: searchGroupParams }));
        setOgnzUserGrpSn(Number(ognzGrpSn));
        setPopupUserName(userName);
        setUserCountShow(true);
    };

    // 전체 선택/해제 핸들러
    const handleAllChecked = () => {
        if (userGroupList.data?.data) {
            if (selectedUsers.length === userGroupList.data?.data?.length) {
                setSelectedUsers([]); // 모두 선택된 상태면 모두 해제
            } else {
                const allUserIds = userGroupList.data?.data?.map((user: SelectUserGroupDto) => user.userId);
                setSelectedUsers(allUserIds); // 모두 선택
            }
        }
    };

    // 개별 체크박스 선택/해제 핸들러
    const handleCheckboxChange = (userId: string) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers.filter(id => id !== userId)); // 이미 선택된 경우 제거
        } else {
            setSelectedUsers([...selectedUsers, userId]); // 선택되지 않은 경우 추가
        }
    };

    // 삭제 버튼 클릭 핸들러
    const handleDeleteClick = async () => {
        if (selectedUsers.length > 0) {
            console.log(selectedUsers);
            const confirmDelete = window.confirm("정말로 선택한 회원을 삭제하시겠습니까?");
            if (confirmDelete) {
                const result = await dispatch(deleteUserAsync.request(selectedUsers)); // Dispatch the delete action

                if (result) {
                    dispatch(findUserGroupListAsync.request(searchParams));
                    setSelectedUsers([]);
                    alert('삭제가 완료되었습니다.');
                } else {
                    alert('삭제 실패.');
                }
            }
        } else {
            alert("삭제할 회원을 선택하세요.");
        }
    };

    // 모달 전체 선택/해제 핸들러
    const handleModalAllChecked = () => {
        if (userGroupMemberList.data?.data) {
            if (selectedModalUsers.length === userGroupMemberList.data?.data?.length) {
                setSelectedModalUsers([]); // 모두 선택된 상태면 모두 해제
            } else {
                const allUserIds = userGroupMemberList.data.data.map((user: SelectUserGroupDto) => user.userId);
                setSelectedModalUsers(allUserIds); // 모두 선택
            }
        }
    };

    // 모달 개별 체크박스 선택/해제 핸들러
    const handleModalCheckboxChange = (userId: string) => {
        if (selectedModalUsers.includes(userId)) {
            setSelectedModalUsers(selectedModalUsers.filter(id => id !== userId)); // 이미 선택된 경우 제거
            console.log("선택된 모달 유저 아이디: " + selectedModalUsers);
        } else {
            setSelectedModalUsers([...selectedModalUsers, userId]); // 선택되지 않은 경우 추가
        }
    };

    // 모달 삭제 버튼 클릭 핸들러
    const handleModalDeleteClick = async () => {
        if (selectedModalUsers.length > 0) {
            const confirmDelete = window.confirm("정말로 선택한 회원을 삭제하시겠습니까?");
            if (confirmDelete) {
                const result = await dispatch(deleteUserGroupMemberAsync.request({ groupId: String(ognzUserGrpSn), userIds: selectedModalUsers })); // Dispatch the delete action

                if (result) {
                    dispatch(findUserGroupListAsync.request(searchParams));
                    setSelectedUsers([]);
                    alert('삭제가 완료되었습니다.');
                    window.location.reload();
                } else {
                    alert('삭제 실패.');
                }
            }
        } else {
            alert("삭제할 회원을 선택하세요.");
        }
    };

    const handleUpdate = (profileDto: ProfileDto) => {
        dispatch(updateUserAsync.request({ userId: profileDto.user.userId, userDto: profileDto.user }))
        alert("사용자 정보가 변경되었습니다.");
        window.location.reload();
    }

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSearch(1);
    };

    const onSearch = async (pageNo: number) => {
        setSearchParams({ ...searchParams, currentPageNo: pageNo });
        dispatch(findUserGroupListAsync.request({
            ...searchParams,
            currentPageNo: pageNo,
        }));
    }

    const onGroupSearch = async (pageNo: number) => {
        setSearchGroupParams({ ...searchGroupParams, currentPageNo: pageNo });
        dispatch(findUserGroupMemberListAsync.request({
            selectUserDto: searchGroupParams,
            ognzGrpSn: ognzUserGrpSn,
        }));
    }

    // 함수 정의
    const handleUpdateRoleCd = (userId: string, newRoleCd: string, sltnId: string) => {
        let name: string;
        if (sltnId === "MAPPRIME") {
            name = 'roleCd';
        } else if (sltnId === "SEMMETA") {
            name = 'roleCd1';
        } else if (sltnId === "GEO_NIC") {
            name = 'roleCd2';
        }
        setModalUserList(prevList =>
            prevList.map(user =>
                user.userId === userId ? { ...user, [name]: newRoleCd } : user
            )
        );

        dispatch(updateGroupUserRoleAsync.request({
            ognzGrpSn: ognzUserGrpSn,
            userId: [userId],
            sltnId: sltnId, // 임시
            role: newRoleCd
        }));
        alert("사용자 정보가 변경되었습니다.");
    };

    // 함수 정의
    const handlerUpdateUserRoleCd = (userId: string, updatedUserDto: UserDto) => {
        // 로컬 상태 업데이트
        setModalUserList(prevList =>
            prevList.map(user =>
                user.userId === userId ? { ...user, userRoleCd: updatedUserDto.userRoleCd } : user
            )
        );
        dispatch(updateUserAsync.request({ userId, userDto: updatedUserDto }));
        alert('권한이 변경되었습니다.');
    };

    useEffect(() => {
        dispatch(getCommCdListAsync.request({ cdGrpId: "PORTAL_TYPE_CD" }));
    }, []);

    useEffect(() => {
        dispatch(findUserGroupListAsync.request(searchParams));
    }, [dispatch]);

    useEffect(() => {
        if (selectedUser.data) {
            setProfileDto({
                ...selectedUser.data.data,
            });
        }
    }, [selectedUser]);

    useEffect(() => {
        if (userGroupMemberList.data?.data) {
            setModalUserList(userGroupMemberList.data.data);
        }
    }, [userGroupMemberList]);

    
    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='기관(기업) 그룹 관리'>
                    {/* 검색 폼 */}
                    <div className='row'>
                        <form onSubmit={handleSearch}>
                            <div className='dash-wrap-cont dash-info-wrap'>
                                {/* 검색 필드 */}
                                {/* 검색어 입력 필드 */}
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        검색어
                                    </div>
                                    <div className="col-3">
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label="Default select example"
                                            value={searchParams.searchType}
                                            onChange={(e) => handleInputChange('searchType', e.target.value)}
                                        >
                                            <option value="user_nm">이름</option>
                                            <option value="user_eml">이메일</option>
                                            <option value="ognz_nm">소속 기관</option>
                                        </select>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            placeholder="이름, 아이디, 이메일, 소속기관으로검색"
                                            aria-label=".form-control-sm example"
                                            value={searchParams.searchKeyword}
                                            onChange={(e) => handleInputChange('searchKeyword', e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* 조회 기간 필드 */}
                                <div className="row">
                                    <div className="col-1 col-form-label">
                                        조회기간
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            value={searchParams.fromDate || ''}
                                            onChange={(e) => handleInputChange('fromDate', e.target.value)}
                                            placeholder=""
                                            aria-label="Start Date"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            value={searchParams.toDate || ''}
                                            onChange={(e) => handleInputChange('toDate', e.target.value)}
                                            placeholder=""
                                            aria-label="End Date"
                                        />
                                    </div>
                                    {/* 조회기간 라디오 버튼들 */}
                                    <div className="col-7">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="dataAll"
                                                checked={selectedPeriod === 'all'}
                                                onChange={() => handlePeriodChange('all')}
                                            />
                                            <label className="form-check-label" htmlFor="dataAll">
                                                전체
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Year"
                                                checked={selectedPeriod === 'year'}
                                                onChange={() => handlePeriodChange('year')}
                                            />
                                            <label className="form-check-label" htmlFor="Year">
                                                1년
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Month6"
                                                checked={selectedPeriod === 'sixMonths'}
                                                onChange={() => handlePeriodChange('sixMonths')}
                                            />
                                            <label className="form-check-label" htmlFor="Month6">
                                                6개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Month1"
                                                checked={selectedPeriod === 'oneMonth'}
                                                onChange={() => handlePeriodChange('oneMonth')}
                                            />
                                            <label className="form-check-label" htmlFor="Month1">
                                                1개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Week"
                                                checked={selectedPeriod === 'oneWeek'}
                                                onChange={() => handlePeriodChange('oneWeek')}
                                            />
                                            <label className="form-check-label" htmlFor="Week">
                                                1주일
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center gap-2 mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={handleReset}
                                    >
                                        초기화
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                    >
                                        검색
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* 유저 목록 테이블 */}
                    <div className="row">
                        <div className="display-count mb-1 d-flex justify-content-between">
                            <span>총 기관 그룹 수 : {userGroupList.data && userGroupList.data.meta && userGroupList.data.meta.totalCnt ? userGroupList.data.meta.totalCnt.toLocaleString() : 0}</span>
                            <button type="button" className="btn btn-secondary btn-sm" onClick={handleDeleteClick}>선택 그룹 삭제</button>
                        </div>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th className='text-center'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="checkAll"
                                            checked={userGroupList.data ? selectedUsers.length === userGroupList.data?.data?.length : false}
                                            onChange={handleAllChecked}
                                        />
                                    </th>
                                    <th className='text-center'>번호</th>
                                    <th className='text-center'>소속 기관</th>
                                    <th className='text-center'>기관 관리자 아이디</th>
                                    <th className='text-center'>기관 관리자 이름</th>
                                    <th className='text-center'>소속 회원</th>
                                    <th className='text-center'>이메일</th>
                                    <th className='text-center'>등록일</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userGroupList.loading ? (
                                    <tr>
                                        <td className="text-center"> loading...</td>
                                    </tr>
                                ) : userGroupList.data?.data?.length > 0 ? (
                                    userGroupList.data?.data?.map((user: SelectUserGroupDto, index: number) => (
                                        <tr key={index}>
                                            <td className='text-center'>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectedUsers.includes(user.userId)}
                                                    onChange={() => handleCheckboxChange(user.userId)}
                                                />
                                            </td>
                                            <td className='text-center'>{
                                                userGroupList.data.meta.totalCnt -
                                                (userGroupList.data.meta.currentPageNo - 1) * userGroupList.data.meta.pageSize - index
                                            }
                                            </td>
                                            <td className='text-center'>{user.ognzName}</td>
                                            <td className='text-center'>{user.userId}</td>
                                            <td className='text-center'>
                                                <button className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={() => handleMoveUserGrpShow(user)}>
                                                    {user.userName}
                                                </button>
                                            </td>
                                            <td className='text-center'>
                                                <button className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={() => handleUserCountShow(user.ognzGrpSn!, user.userName!)}>
                                                    {user.userCount}
                                                </button>
                                            </td>
                                            <td className='text-center'>{user.email}</td>
                                            <td className='text-center'>{new Date(user.regDt!).toLocaleDateString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {userGroupList.data?.meta ? (
                            <Pagination key="1" pageingDto={userGroupList.data.meta} onClick={onSearch} />
                        ) : (<span></span>)
                        }
                    </div>
                </ContentLayout>
                <Modal show={userInfoShow} onHide={handleUserInfoClose} dialogClassName="custom-modal-width">
                    <Modal.Header closeButton>
                        <Modal.Title>{profileDto.user.userName}님 회원 정보</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='dash-ti mb-2'>필수 정보</h3>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>이름</th>
                                    <td style={{ paddingLeft: '10px' }}>
                                        {profileDto.user.userName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>회원유형</th>
                                    <td style={{ paddingLeft: '10px' }}>
                                        {profileDto.user.userRoleCd === 'ADMIN' ? '관리자' : '사용자'}
                                    </td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td style={{ paddingLeft: '10px' }}>
                                        {profileDto.user.userId}
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td style={{ paddingLeft: '10px' }}>{profileDto.user.email}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <h3 className='dash-ti mb-2'>구독 정보</h3>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기관</th>
                                    <td colSpan={2}>{profileDto.user.ognzName}</td>
                                </tr>
                                {profileDto.products.map((product, index) => (
                                    <tr key={index}>
                                        {index === 0 && (
                                            <th rowSpan={profileDto.products.length}>구매제품</th>
                                        )}
                                        <td>{product.sltnNm}</td>
                                        <td>{product.startDate}~{product.endDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleUserInfoClose}>
                            닫기
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={userCountShow} onHide={handleUserCountClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{popupUserName}님 소속 회원 정보</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='dash-ti mb-2'>필수 정보</h3>
                        <div className="d-flex justify-content-end mb-2">
                            <button type="button" className="btn btn-secondary btn-sm" onClick={handleModalDeleteClick}>
                                선택 회원 삭제
                            </button>
                        </div>
                        {/* <Table bordered size="xl"> */}
                            {/* <table className="table table-bordered table-hover table-responsive"> */}
                            <Table responsive bordered table-bordered table-hover>
                                <colgroup>
                                    <col width={"3%"} />
                                    <col width={"5%"} />
                                    <col width={"8%"} />
                                    <col width={"8%"} />
                                    <col width={"auto"} />
                                    <col width={"11%"} />
                                    <col width={"14%"} />
                                    <col width={"14%"} />
                                    <col width={"14%"} />
                                    <col width={"10%"} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className='text-center'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkAll"
                                                checked={userGroupMemberList.data ? selectedModalUsers.length === userGroupMemberList.data?.data?.length : false}
                                                onChange={handleModalAllChecked}
                                            />
                                        </th>
                                        <th className='text-center'>번호</th>
                                        <th className='text-center'>아이디</th>
                                        <th className='text-center'>이름</th>
                                        <th className='text-center'>이메일</th>
                                        <th className='text-center'>권한</th>
                                        <th className='text-center'>영상촬영 권한</th>
                                        <th className='text-center'>AI 데이터 권한</th>
                                        <th className='text-center'>디지털트윈 권한</th>
                                        <th className='text-center'>등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userGroupMemberList.loading ? (
                                        <tr>
                                            <td className="text-center"> loading...</td>
                                        </tr>
                                    ) : modalUserList.map.length > 0 ? (
                                        modalUserList.map((user: SelectUserGroupDto, index: number) => (
                                            <tr key={index}>
                                                <td className='text-center'>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedModalUsers.includes(user.userId)}
                                                        onChange={() => handleModalCheckboxChange(user.userId)}
                                                    />
                                                </td>
                                                <td>{
                                                    userGroupMemberList.data.meta.totalCnt -
                                                    (userGroupMemberList.data.meta.currentPageNo - 1) * userGroupMemberList.data.meta.pageSize - index
                                                }
                                                </td>
                                                <td>{user.userId}</td>
                                                <td>{user.userName}</td>
                                                <td>{user.email}</td>
                                                <td>
                                                    <Form.Select
                                                        value={user.userRoleCd}  // 현재 선택된 roleCode 값
                                                        onChange={(e) => {
                                                            const newRole = e.target.value;
                                                            const updatedUserDto = { ...user, userRoleCd: newRole };
                                                            handlerUpdateUserRoleCd(user.userId, updatedUserDto);
                                                        }}
                                                        >
                                                        <option value="ADMIN">관리자</option>
                                                        <option value="USER">사용자</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        value={user.roleCd2}  // 현재 선택된 roleCode 값
                                                        onChange={(e) => handleUpdateRoleCd(user.userId, e.target.value, "GEO_NIC")}
                                                        >
                                                        <option value="ADMIN">프로젝트 관리</option>
                                                        <option value="USER">프로젝트 조회</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        value={user.roleCd1}  // 현재 선택된 roleCode 값
                                                        onChange={(e) => handleUpdateRoleCd(user.userId, e.target.value, "SEMMETA")}
                                                        >
                                                        <option value="ADMIN">프로젝트 관리</option>
                                                        <option value="USER">프로젝트 조회</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        value={user.roleCd}  // 현재 선택된 roleCode 값
                                                        onChange={(e) => handleUpdateRoleCd(user.userId, e.target.value, "MAPPRIME")}
                                                        >
                                                        <option value="ADMIN">프로젝트 관리</option>
                                                        <option value="USER">프로젝트 조회</option>
                                                    </Form.Select>
                                                </td>
                                                        <td>{new Date(user.regDt!).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        {/* </Table> */}
                        {userGroupMemberList.data && userGroupMemberList.data.meta ? (
                            <Pagination key="1" pageingDto={userGroupMemberList.data.meta} onClick={onGroupSearch} />
                        ) : (<span></span>)
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleUserCountClose}>
                            확인
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}
export default UserGroupList;