import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Dashboard.scss';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../modules';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { getInfoseedDashboardAsync, getMapprimeDashboardAsync, getNeospectraJobsStatsAsync, getNeospectraProjectsStatsAsync, getNeospectraUsagesServiesLeftAsync } from '../../modules/dashboard';
import { findUserGroupListAsync, findUserGroupMemberListAsync } from '../../modules/usergroup';
import { SelectUserDto } from '../../types/UserDto';
import { SelectUserGroupDto } from '../../types/UserGroupDto';
import Pagination from '../../components/Pagination';
import { filesize } from 'filesize';

interface totalDataMapProps {
    useData: string;
    totalData: string;
    usePer: number;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Dashboard: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { ognzId, ognzGrpSn } = location.state || {}; // ognzGrpSn이 없을 때 대비

    const buyUserList = useSelector((state: RootState) => state.prdct.buyUserList);

    const infoseedDashboard = useSelector((state: RootState) => state.dashboard.infoseedDashboard);   // 인포시드 대쉬보드
    const neoJobsStat = useSelector((state: RootState) => state.dashboard.neoJobsStat);   // 작업 통계
    const neoProjectStat = useSelector((state: RootState) => state.dashboard.neoProjectsStat); // 프로젝트 통계
    const neoUsagesServies = useSelector((state: RootState) => state.dashboard.neoUsagesServies);   // 사용량 조회
    const mapprimeDashboard = useSelector((state: RootState) => state.dashboard.mapprimeDashboard);   // 맵프라임 대쉬보드

    // 탭 상태 관리
    const [activeTab, setActiveTab] = useState<string>('GEO_NIC');
    const [startDt, setStartDt] = useState<string>("");
    const [endDt, setEndDt] = useState<string>("");

    // 그래프 및 데이터 관련 상태
    const statInfo = useSelector((state: RootState) => state.stat);
    const contactUsInfo = useSelector((state: RootState) => state.support.data);
    const [totalDataLogMap, setTotalDataLogMap] = useState();
    const [projectDataMap, setProjectDataMap] = useState<any>();
    const [projectCntDataMap, setProjectCntDataMap] = useState<any>();
    const [assetStatus, setAssetStatus] = useState<any[]>([]);
    const [modalUserList, setModalUserList] = useState<SelectUserGroupDto[]>([]);
    const userGroupMemberList = useSelector((state: RootState) => state.usergroup.userGroupMemberList);
    const noticeInfo = useSelector((state: RootState) => state.support.notices);
    const [searchGroupParams, setSearchGroupParams] = useState<SelectUserDto>({
        currentPageNo: 1,
    });

    const onSearch = async (pageNo: number) => {
        setSearchGroupParams(prev => ({
            ...prev,
            currentPageNo: pageNo
        }));
        dispatch(findUserGroupMemberListAsync.request({
            ognzGrpSn: ognzGrpSn,
            selectUserDto: searchGroupParams
        }));
    }

    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function bytesToSize(bytes: number): string {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 'n/a';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
    }

    const [totalDataMap3, setTotalDataMap3] = useState<totalDataMapProps>({
        useData: "0GB",
        totalData: "0GB",
        usePer: 0.0
    });

    useEffect(() => {
        const fetchBuyUser = async () => {
            console.log('ognzGrpSn:', ognzGrpSn);
            // 기관 그룹 사용자 목록
            dispatch(findUserGroupMemberListAsync.request({ ognzGrpSn: ognzGrpSn, selectUserDto: searchGroupParams }));

            const startDay = new Date();

            const daysToAdd = 10;
            startDay.setDate(startDay.getDate() - daysToAdd);

            const endDate = formatDate(new Date());
            const startDate = formatDate(startDay);

            setStartDt(startDate);
            setEndDt(endDate);

            // 인포시드
            dispatch(getInfoseedDashboardAsync.request({ ognzId: ognzId, ognzUserGrpSn: ognzGrpSn }));

            // 네오스펙트라 프로젝트 그래프
            await dispatch(getNeospectraProjectsStatsAsync.request({
                param: {
                    org_id: ognzGrpSn,
                    start_date: startDate,
                    end_date: endDate,
                }
            }));

            // 네오스펙트라 작업 그래프
            await dispatch(getNeospectraJobsStatsAsync.request({
                param: {
                    org_id: ognzGrpSn,
                    start_date: startDate,
                    end_date: endDate,
                }
            }));

            // 서비스별 남은 사용량 조회
            dispatch(getNeospectraUsagesServiesLeftAsync.request(ognzGrpSn));
            // 맵프라임 통계 조회
            dispatch(getMapprimeDashboardAsync.request(ognzGrpSn));
        };

        fetchBuyUser();
    }, []);


    useEffect(() => {
        console.log("mapprimeDashboard", mapprimeDashboard);
    }, [mapprimeDashboard]);

    useEffect(() => {
        if (mapprimeDashboard && mapprimeDashboard.data && mapprimeDashboard.data.result != "error") {


            const totalData = Number(mapprimeDashboard.data.size.total);
            const currentData = Number(mapprimeDashboard.data.size.current);

            const dataMap = {
                totalData: bytesToSize(totalData),
                useData: bytesToSize(currentData),
                usePer: currentData / totalData * 100
            }

            setTotalDataMap3(dataMap);

            setTotalDataLogMap(mapprimeDashboard.data.log);

            setProjectDataMap(mapprimeDashboard.data.topProjects);

            setProjectCntDataMap(mapprimeDashboard.data.count);

            const asset = [];
            asset.push({ name: "생성중인 에셋", value: (mapprimeDashboard?.data?.count.asset?.progress ? mapprimeDashboard?.data?.count.asset?.progress : 0) })
            asset.push({ name: "완료된 에셋", value: (mapprimeDashboard?.data?.count.asset?.done ? mapprimeDashboard?.data?.count.asset?.done : 0) })
            setAssetStatus(asset)

            console.log("mapprimeDashboard: ", mapprimeDashboard);
        }
    }, [mapprimeDashboard]);

    useEffect(() => {
        console.log("assetStatus: ", assetStatus);
    }, [assetStatus]);

    useEffect(() => {
        if (userGroupMemberList.data?.data) {
            setModalUserList(userGroupMemberList.data.data);
        }
    }, [userGroupMemberList]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />

                <div className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12"><h2 className='page-title'>기관 그룹 대쉬보드</h2></div>
                        </div>
                        <div className="row">
                            <div className="display-count mb-1 d-flex justify-content-between">
                                <span>기관 그룹 사용자 목록</span>
                            </div>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className='text-center'>번호</th>
                                        <th className='text-center'>소속 기관</th>
                                        <th className='text-center'>아이디</th>
                                        <th className='text-center'>이름</th>
                                        <th className='text-center'>이메일</th>
                                        <th className='text-center'>사용자 권한</th>
                                        <th className='text-center'>프로젝트 권한</th>
                                        <th className='text-center'>등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userGroupMemberList.loading ? (
                                        <tr>
                                            <td className="text-center"> loading...</td>
                                        </tr>
                                    ) : modalUserList.map.length > 0 ? (
                                        modalUserList.map((user: SelectUserGroupDto, index: number) => (
                                            <tr key={index}>
                                                <td className='text-center'>{
                                                    userGroupMemberList.data.meta.totalCnt -
                                                    (userGroupMemberList.data.meta.currentPageNo - 1) * userGroupMemberList.data.meta.pageSize - index
                                                }
                                                </td>
                                                <td className='text-center'>{user.ognzName}</td>
                                                <td className='text-center'>{user.userId}</td>
                                                <td className='text-center'>{user.userName}</td>
                                                <td className='text-center'>{user.email}</td>
                                                <td className='text-center'>{user.userRoleCd === 'ADMIN' ? '관리자' : '사용자'}</td>
                                                <td className='text-center'>{user.roleCd === 'ADMIN' ? '관리자' : '사용자'}</td>
                                                <td className='text-center'>{new Date(user.regDt!).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {userGroupMemberList.data?.meta ? (
                                <Pagination key="1" pageingDto={userGroupMemberList.data.meta} onClick={onSearch} />
                            ) : (<span></span>)
                            }
                        </div>

                        <div className="mb-4"></div>

                        <div className="row">
                            <div className='col-12'>
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k!)}
                                    id="fill-tab-example"
                                    className="mb-3"
                                    fill
                                >
                                    <Tab eventKey="GEO_NIC" title="영상촬영 서비스"></Tab>
                                    <Tab eventKey="SIMMETA" title="AI 데이터 생성관리 서비스"></Tab>
                                    <Tab eventKey="MAPPRIME" title="디지털트윈 지도 서비스"></Tab>
                                </Tabs>
                            </div>
                        </div>

                        {/* 탭에 따른 내용 렌더링 */}
                        {activeTab === 'GEO_NIC' && (
                            <>
                                {/* GEO_NIC 탭의 내용 */}
                                <div className="row">
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont dash-info-wrap'>
                                            <h3 className='dash-ti'>비행촬영 프로젝트</h3>
                                            <div className='flex'>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>등록된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.DRN_PRJ_CNT ? Number(infoseedDashboard.data?.data?.DRN_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>촬영요청</h4>
                                                        <strong>{infoseedDashboard.data?.data?.RE_DRN_PRJ_CNT ? Number(infoseedDashboard.data?.data?.RE_DRN_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>완료 된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.COM_DRN_PRJ_CNT ? Number(infoseedDashboard.data?.data?.COM_DRN_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* GEO_NIC 탭의 내용 */}
                                <div className="row">
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont dash-info-wrap'>
                                            <h3 className='dash-ti'>현장조사사진 프로젝트</h3>
                                            <div className='flex'>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>등록된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.PIC_PRJ_CNT ? Number(infoseedDashboard.data?.data?.PIC_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>저장된 파일 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.PIC_FILE_CNT ? Number(infoseedDashboard.data?.data?.PIC_FILE_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>완료된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.COM_PIC_PRJ_CNT ? Number(infoseedDashboard.data?.data?.COM_PIC_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont'>
                                            <div className="col-content">
                                                <h3 className='dash-ti'>장비/인력 현황</h3>
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"드론장비"} style={{ width: "10%", minWidth: "100px" }}>{"드론장비"}</label>
                                                    <span style={{ width: `10%` }}>{Number(infoseedDashboard.data?.data?.USE_DRN_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_DRN_CNT)} % </span>
                                                    <div className="progress-group">
                                                        <progress id={'item.id'} max="100" value={Number(infoseedDashboard.data?.data?.USE_DRN_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_DRN_CNT)}></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_DRN_CNT}`}</span>
                                                    </div>
                                                </div>
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"모바일 장비"} style={{ width: "10%", minWidth: "100px" }}>{"모바일 장비"}</label>
                                                    <span style={{ width: `10%` }}>{Number(infoseedDashboard.data?.data?.USE_EQM_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_EQM_CNT)} % </span>
                                                    <div className="progress-group">
                                                        <progress id={'item.id'} max="100" value={Number(infoseedDashboard.data?.data?.USE_EQM_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_EQM_CNT)}></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_EQM_CNT}`}</span>
                                                    </div>
                                                </div>
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"조종사"} style={{ width: "10%", minWidth: "100px" }}>{"조종사"}</label>
                                                    <span style={{ width: `10%` }}>{Number(infoseedDashboard.data?.data?.USE_PLT_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_PLT_CNT)} % </span>
                                                    <div className="progress-group">
                                                        <progress id={'item.id'} max="100" value={Number(infoseedDashboard.data?.data?.USE_PLT_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_PLT_CNT)}></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_PLT_CNT}`}</span>
                                                    </div>
                                                </div>
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"조사자"} style={{ width: "10%", minWidth: "100px" }}>{"조사자"}</label>
                                                    <span style={{ width: `10%` }}>{Number(infoseedDashboard.data?.data?.USE_INVS_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_INVS_CNT)} % </span>
                                                    <div className="progress-group">
                                                        <progress id={'item.id'} max="100" value={Number(infoseedDashboard.data?.data?.USE_INVS_CNT) / Number(infoseedDashboard.data?.data?.TOTAL_INVS_CNT)}></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_INVS_CNT}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 프로젝트 별 데이터 현황 */}
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont'>
                                            <div className="col-content">
                                                <h3 className='dash-ti'>프로젝트별 데이터 사용량</h3>
                                                {infoseedDashboard.data?.data?.PRJ_DATA_AMOUNT.map((project: any, index: number) => {
                                                    const usagePercentage = (Number(project.USE_AMOUNT) / Number(project.FILE_CNT)) * 100;
                                                    return (
                                                        <div key={index} className={`progress-ui theme-blue`}>
                                                            <label htmlFor={project.PRJ_NM} style={{ width: "10%", minWidth: "100px" }}>{project.PRJ_NM}</label>
                                                            <span style={{ width: `10%` }}>{`${usagePercentage.toFixed(2)}%`}</span>
                                                            <div className="progress-group">
                                                                <progress id={project.PRJ_NM} max="100" value={usagePercentage}></progress>
                                                                <span className="count">{`${project.USE_AMOUNT} / ${project.FILE_CNT}`}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 막대 그래프 */}
                                {/* <div className="row">
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont'>
                                            <h3 className='dash-ti'>프로젝트 별 데이터 사용량</h3>
                                            <div style={{ height: 300 }}>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart data={infoseedDashboard.data?.data?.PRJ_DATA_AMOUNT} >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="PRJ_NM" />
                                                        <YAxis type='number' allowDecimals={false} />
                                                        <Tooltip />
                                                        <Bar dataKey="USE_AMOUNT" fill="#8884d8" name="개" />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        )}

                        {activeTab === 'SIMMETA' && (
                            <>
                                {neoUsagesServies && neoUsagesServies.data ? (
                                    <>
                                        {/* GEO_NIC 탭의 내용 */}
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className='dash-wrap-cont dash-info-wrap'>
                                                    <h3 className='dash-ti'>참여중인 프로젝트</h3>
                                                    <div className='flex'>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>객체탐지</h4>
                                                                <strong>{neoUsagesServies.data[0].used.toLocaleString()}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>객체삭제</h4>
                                                                <strong>{neoUsagesServies.data[1].used.toLocaleString()}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='dash-wrap-cont'>
                                                    <div className="col-content">
                                                        <h3 className='dash-ti'>서비스 남은 수량</h3>
                                                        <div className={`progress-ui theme-blue`}>
                                                            <label htmlFor={neoUsagesServies.data[0].category_code} style={{ width: "10%", minWidth: "100px" }}>{neoUsagesServies.data[0].category_name}</label>
                                                            <span style={{ width: `10%` }}>{neoUsagesServies.data[0].percent} % </span>
                                                            <div className="progress-group">
                                                                <progress id={'item.id'} max="100" value={neoUsagesServies.data[0].percent}></progress>
                                                                <span className="count">{`${neoUsagesServies.data[0].used.toLocaleString()} / ${neoUsagesServies.data[0].available.toLocaleString()}`}</span>
                                                            </div>
                                                        </div>
                                                        <div className={`progress-ui theme-blue`}>
                                                            <label htmlFor={neoUsagesServies.data[1].category_code} style={{ width: "10%", minWidth: "100px" }}>{neoUsagesServies.data[1].category_name}</label>
                                                            <span style={{ width: `10%` }}>{neoUsagesServies.data[1].percent} % </span>
                                                            <div className="progress-group">
                                                                <progress id={'item.id'} max="100" value={neoUsagesServies.data[1].percent}></progress>
                                                                <span className="count">{`${neoUsagesServies.data[1].used.toLocaleString()} / ${neoUsagesServies.data[1].available.toLocaleString()}`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* 회원 가입 현황 및 제품 구매 현황 그래프 */}
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 탐지 (프로젝트)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoProjectStat.data?.resultList1} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 탐지 (작업)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoJobsStat.data?.resultList1} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 삭제 (프로젝트)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoProjectStat.data?.resultList2} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 삭제 (작업)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoJobsStat.data?.resultList2} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p>데이터를 불러오는 중입니다...</p>
                                )}
                            </>
                        )}

                        {activeTab === 'MAPPRIME' && (
                            <>
                                {mapprimeDashboard && mapprimeDashboard.data ? (
                                    <>
                                        {/* GEO_NIC 탭의 내용 */}
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className='dash-wrap-cont dash-info-wrap'>
                                                    <h3 className='dash-ti'>참여중인 프로젝트</h3>
                                                    <div className='flex'>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>프로젝트 개수</h4>
                                                                <strong>{mapprimeDashboard?.data?.count?.project.total || 0}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>에셋 개수</h4>
                                                                <strong>
                                                                    {
                                                                        (mapprimeDashboard?.data?.count?.asset?.total || 0)
                                                                    }
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* 프로젝트 별 데이터 현황 */}
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>프로젝트 별 데이터 현황</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={mapprimeDashboard?.data?.topProjects} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis type='number' allowDecimals={false} width={80} tickFormatter={(value) => filesize(value)}/>
                                                                <Tooltip formatter={(value) => filesize(Number(value))} />
                                                                <Bar dataKey="size" fill="#8884d8" name="용량" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>에셋 생성 현황</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <PieChart>
                                                                <Pie
                                                                    data={assetStatus}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    innerRadius={60}
                                                                    outerRadius={80}
                                                                    fill="#8884d8"
                                                                    paddingAngle={5}
                                                                    dataKey="value"
                                                                >
                                                                    {assetStatus.map((entry, index) => (
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={COLORS[index % COLORS.length]}
                                                                        />
                                                                    ))}
                                                                </Pie>
                                                                <Tooltip />
                                                                <Legend />
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='dash-wrap-cont'>
                                                        <div className="col-content">
                                                            <h3 className='dash-ti'>프로젝트, 에셋 현황</h3>
                                                            <div className={`progress-ui theme-blue`}>
                                                                <label htmlFor={"사용 용량"} style={{ width: "10%", minWidth: "100px" }}>{"프로젝트"}</label>
                                                                <span style={{ width: `10%` }}>{((mapprimeDashboard.data?.count?.project?.current / mapprimeDashboard.data?.count?.project?.total) * 100)} % </span>
                                                                <div className="progress-group">
                                                                    <progress id={'item.id'} max="100" value={(mapprimeDashboard.data?.count?.project?.current / mapprimeDashboard.data?.count?.project?.total) * 100}></progress>
                                                                    <span className="count">{`${mapprimeDashboard.data?.count?.project?.current / mapprimeDashboard.data?.count?.project?.total}`}</span>
                                                                </div>
                                                            </div>
                                                            <div className={`progress-ui theme-blue`}>
                                                                <label htmlFor={"사용 용량"} style={{ width: "10%", minWidth: "100px" }}>{"에셋"}</label>
                                                                <span style={{ width: `10%` }}>{((mapprimeDashboard.data?.count?.asset?.progress + mapprimeDashboard.data?.count?.asset?.done) / mapprimeDashboard.data?.count?.asset?.total) * 100} % </span>
                                                                <div className="progress-group">
                                                                    <progress id={'item.id'} max="100" value={((mapprimeDashboard.data?.count?.asset?.progress + mapprimeDashboard.data?.count?.asset?.done) / mapprimeDashboard.data?.count?.asset?.total) * 100}></progress>
                                                                    <span className="count">{`${(mapprimeDashboard.data?.count?.asset?.progress + mapprimeDashboard.data?.count?.asset?.done) / mapprimeDashboard.data?.count?.asset?.total}`}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                ) :
                                    (
                                        <p>데이터를 불러오는 중입니다...</p>
                                    )}
                                <div className="d-flex justify-content-right gap-2 mt-3" style={{ justifyContent: 'flex-end' }}>
                                    <button type="button" className="btn btn-secondary  m-1" onClick={() => navigate(-1)}>목록</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* content-page */}
        </div>
    );
};

export default Dashboard;
