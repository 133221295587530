import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { RootState } from '../../modules';
import { useDispatch, useSelector } from 'react-redux';
import { getContactUsListAsync, getNoticesAsync } from '../../modules/support';
import { getOverviewAsync, getPurcasesAsync, getSignupAsync } from '../../modules/stat';



const MainPage: FC = () => {
    const dispatch = useDispatch();
    // 그래프

    // 게시판 관리
    const statInfo = useSelector((state: RootState) => state.stat);
    const contactUsInfo = useSelector((state: RootState) => state.support.data);
    const noticeInfo = useSelector((state: RootState) => state.support.notices);
    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy: "REG_DT"
        , currentPageNo: 1
        , pageUnit: 5
    });

    useEffect(() => {
        dispatch(getOverviewAsync.request());     // 전체 회원
        dispatch(getSignupAsync.request());     // 회원 현황
        dispatch(getPurcasesAsync.request());     // 제품 구매 현황
        dispatch(getContactUsListAsync.request(searchParams));         // 문의내역 관리
        dispatch(getNoticesAsync.request(searchParams));        // 공지사항 관리
    }, []);

    useEffect(() => {
        console.log(statInfo);
    }, [contactUsInfo])

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <div className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12"><h2 className='page-title'>시스템 모니터링</h2></div>
                        </div>

                        <div className="row">
                            <div className='col-12'>
                                <div className='dash-wrap-cont dash-info-wrap'>
                                    <h3 className='dash-ti'>주요 관리 정보</h3>
                                    <div className='flex'>
                                        <div className='dash-info-item'>
                                            <Link to={'/userList'}>
                                                <h4>전체 회원</h4>
                                                <strong>{statInfo.data?.data?.data?.totalMembers ? Number(statInfo.data.data.data.totalMembers).toLocaleString() : 0}</strong>
                                            </Link>
                                        </div>
                                        <div className='dash-info-item'>
                                            <Link to={'/ognzList'}>
                                                <h4>전체 기관</h4>
                                                <strong>{statInfo.data?.data?.data?.totalOrganizations ? Number(statInfo.data.data.data.totalOrganizations).toLocaleString() : 0}</strong>
                                            </Link>
                                        </div>
                                        <div className='dash-info-item'>
                                            <Link to={'/ognzMngList'}>
                                                <h4>기관 담당자</h4>
                                                <strong>{statInfo.data?.data?.data?.totalManagers ? Number(statInfo.data.data.data.totalManagers).toLocaleString() : 0}</strong>
                                            </Link>
                                        </div>
                                        <div className='dash-info-item'>
                                            <Link to={'/scrtn'}>
                                                <h4>이달의 결제 건수</h4>
                                                <strong>{statInfo.data?.data?.data?.monthlyTransactions ? Number(statInfo.data.data.data.monthlyTransactions).toLocaleString() : 0}</strong>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-6'>
                                <div className='dash-wrap-cont'>
                                    <h3 className='dash-ti'>회원 가입 현황</h3>
                                    <div style={{ height: 300 }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart data={statInfo.signup?.data?.data} >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                <YAxis type='number' allowDecimals={false} />
                                                <Tooltip />
                                                <Bar dataKey="count" fill="#8884d8" name="명" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='dash-wrap-cont'>
                                    <h3 className='dash-ti'>제품 구매 현황</h3>
                                    <div style={{ height: 300 }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart data={statInfo.purchases?.data?.data}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis type='number' allowDecimals={false} />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="value" stroke="#8884d8" name="제품 구매 수" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-6'>
                                <div className='dash-wrap-cont'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h3 className='dash-ti flex'>문의내역 관리 </h3>
                                        <Link to={'/contactUsList'}>더 보기</Link>
                                    </div>
                                    <ul className="dash-notice-li">
                                        {contactUsInfo.data?.data?.length > 0 ? (
                                            contactUsInfo.data?.data?.map((contactUs: any, idx: number) =>
                                                <li className="tbody" key={idx}><Link className='flex' to={'/ContactUsDetail/'+contactUs.inqSn}><span className="category">[{contactUs.rspnsCn ? "답변완료" : "처리중"}]</span><p className="subject">{contactUs.inqTtl}</p><span className="data">{contactUs.regDt}</span></Link></li>
                                            )) : (
                                            <span></span>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='dash-wrap-cont'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h3 className='dash-ti felx'>공지사항 관리 </h3>
                                        <Link to={'/noticeList'}>더 보기</Link>
                                    </div>
                                    <ul className="dash-notice-li">
                                        {noticeInfo.data?.data?.length > 0 ? (
                                            noticeInfo.data?.data?.map((notice: any) =>
                                                <li className="tbody" key={notice.ntcSn}>
                                                    <Link className='flex' to={'/noticeWrite/' + notice.ntcSn}>
                                                        <span className="category">[{notice.ntcTypeNm}]</span>
                                                        <p className="subject">{notice.ntcTtl}</p>
                                                        <span className="data">{notice.regDt}</span>
                                                    </Link>
                                                </li>
                                            )) : (
                                            <span></span>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* content-page */}
        </div>
    )
}



export default MainPage;
