import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import Table from 'react-bootstrap/Table';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../components/editor.scss';  
import Form from 'react-bootstrap/Form';
import RadioBox from '../../components/RadioBox';
import StartEndDt from "../../components/StartEndDt";
import { useNavigate } from 'react-router-dom';
import { getFileDownLoad } from '../../api/common'

import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { CreateFaqDto } from '../../types/support/CreateFaqDto';
import { UpdateFaqDto } from '../../types/support/UpdateFaqDto';
import { deleteFaqAsync , getFaqAsync , putFaqAsync , postFaqAsync } from '../../modules/support';

const FaqWrite:FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 결과 리턴값 설정
    const faqDetail   = useSelector((state:RootState) => state.support.faq);
    const insertResult   = useSelector((state:RootState) => state.support.faqInsert);
    const updateResult   = useSelector((state:RootState) => state.support.faqUpdate);
    const deleteResult   = useSelector((state:RootState) => state.support.faqDelete);
    const delFileResult  = useSelector((state:RootState) => state.common.delFile);
    
    // 변수 선언
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'doc', 'hwp', 'pdf']; // 허용할 파일 확장자
    const [content, setContent] = useState<string>('');
    const [action, setAction] = useState<string>('');
    const [searchParams, setParamData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 10
    });
    const [saveParams, setFormData] = useState<CreateFaqDto>({
        faqTypeCd: ""
      , faqTtl: ""
      , faqCn: ""
      , pstgSttsCd: ""
    }); 
    const [updateParams, setUpdateFormData] = useState<UpdateFaqDto>({
        faqSn:0
      , faqTypeCd: ""
      , faqTtl: ""
      , faqCn: ""
      , pstgSttsCd: ""
     }); 

    // 화면 접근시 처리 로직 ( 수정 으로 접근시 조회)
    useEffect(() => {
        const currentPath = window.location.pathname.split('/'); // 경로
            
        if (currentPath[2]) {
            const faqSn = currentPath[currentPath.length-1];
            
            if (faqSn && Number(faqSn) > 0) {
                searchParams.faqSn = Number(faqSn);
                dispatch(getFaqAsync.request(Number(faqSn)));
            }
        }

      },[]);

    // 수정 으로 접근시 데이터 조회후 호출
    useEffect(() => {
        if (faqDetail.data && faqDetail.data.data && searchParams.faqSn && searchParams.faqSn > 0) {
            console.log('조회 결과:', JSON.stringify(faqDetail, null, 2));
                setFormData((prev) => ({ ...prev, ...faqDetail.data.data }));

                setContent(faqDetail.data.data.faqCn);
        }
    },[faqDetail]);

    // 파일 업로드 클릭시 
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            const validFiles = selectedFiles.filter((file) => {
                const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
                return allowedExtensions.includes(fileExtension.toLowerCase());
            });

            if (validFiles.length === 0) {
                alert('허용되지 않는 파일 형식입니다.  jpg, jpeg, png, doc, hwp, pdf 파일만 업로드 가능합니다.');
                event.target.value = "";
                setFormData((prev) => ({ ...prev, fileList: [] }));
                return;
            } else {
                setFormData((prev) => ({ ...prev, fileList: validFiles }));
            }
        }
    };

    // 내용 editer 값 수정시
    const handleChange = (value: string) => {
       setFormData((prev) => ({ ...prev, faqCn : value }));
    };

    // input 객체 변경시 
    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // radio 버튼 변경시
    const handleRadioChange = (id : string , value: string) => {
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    // 조회기간 버튼 클릭 핸들러
    const handleSearchDtChange = (startId : string , startValue: string , endId : string , endValue: string) => {
        setFormData((prev) => ({ ...prev, [startId]: startValue , [endId]: endValue })); // 선택된 값 업데이트
    };

    // Validation Check
    const inputCheck = async () => {
        var result = true;
        var resultMsg = "";

        if (!saveParams.faqTypeCd) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }

            resultMsg = 'FAQ유형을 선택해 주십시오.';
            result = false;
        }

        if (!saveParams.pstgSttsCd) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }
            resultMsg +='게시 여부를 선택해 주십시오.';
            result = false;
        }

        if (!saveParams.faqTtl) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }
            resultMsg +='제목을 입력해 주십시오.';
            result = false;
        }

        if (!saveParams.faqCn) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }
            resultMsg +='내용을 선택해 주십시오.';
            result = false;
        }

        if (!result) {
            alert(resultMsg);
        }

        return result;
    }

    // 저장 버튼 클릭시
    const onSave = async () => {
        if (await inputCheck()) {
            await dispatch(postFaqAsync.request(saveParams));
            await setAction("INSERT");            
        }
    }

    // 저장 처리 결과 후처리
    useEffect(() => {
        if (action == "INSERT") {
            if (insertResult && insertResult.data) {
                const status = insertResult.data.status;
        
                if (status == "error") {
                    alert(insertResult.data.error.message);
                } else if (status == "success") {
                    alert("저장 되었습니다.");
                    navigate('/faqList');
                }
            }
        }
        
    },[insertResult]);

    // 취소 버튼 클릭시
    const oncancel = async () => {
        navigate(-1);
    }


    // 수정 버튼 클릭시 저장 DTO 값을 수정 DTO 로 복사 한다.
    const onUpdate = async () => {
        if (await inputCheck()) {
            const faqSn = searchParams.faqSn;

            // console.log('조회 결과2:', JSON.stringify(updateParams, null, 2))
        
            if (faqSn && faqSn > 0) {
                await setUpdateFormData((prev) => ({ ...prev, faqSn : faqSn 
                    , faqTypeCd : saveParams.faqTypeCd 
                    , faqTtl : saveParams.faqTtl
                    , faqCn : saveParams.faqCn
                    , pstgSttsCd : saveParams.pstgSttsCd
                }));    
            };
        }
    }

    // 수정 DTO 에 값이 변경되면 수정 처리
    useEffect(() => {
        if (updateParams && updateParams.faqSn && updateParams.faqSn > 0) {
            //console.log('조회 결과3:', JSON.stringify(updateParams, null, 2));
            dispatch(putFaqAsync.request(updateParams));
            setAction("UPDATE");
        }
    },[updateParams]);

    // 수정 후처리
    useEffect(() => {
        if (action == "UPDATE") {
            if (updateResult && updateResult.data) {
                const status = updateResult.data.status;
        
                if (status == "error") {
                    alert(updateResult.data.error.message);
                } else if (status == "success") {
                    alert("수정 되었습니다.");
                    navigate(0);
                }
            }
        }
        
    },[updateResult]);
     

    // 삭제 버튼 클릭시
    const onDel = async () => {
        const confirmDelete = window.confirm("정말로 삭제하시겠습니까?");
        if (confirmDelete) {
            if (saveParams.faqSn) {
                await dispatch(deleteFaqAsync.request(saveParams.faqSn));
                await setAction("DELETE");
            }
        } 
    };

    // 삭제 후처리
    useEffect(() => {
        if (action == "DELETE") {
            if (deleteResult && deleteResult.data) {
                const status = deleteResult.data.status;
        
                if (status == "error") {
                    alert(deleteResult.data.error.message);
                } else if (status == "success") {
                    alert("삭제 되었습니다.");
                    navigate(-1);
                }
            }
        }
        
    },[deleteResult]);
    
    // 하단 버튼 HTML 생성
    const actBtnHtml = [];
    if (searchParams.faqSn) {
        actBtnHtml.push(
            <div className="d-flex justify-content-right gap-2 mt-3" style={{justifyContent: 'flex-end'}}>
                <button type="button" className="btn btn-secondary  m-1" onClick={oncancel}>목록</button>
                <button type="button" className="btn btn btn-primary m-1" onClick={onUpdate}>수정</button>
                <button type="button" className="btn btn btn-primary m-1" onClick={onDel}>삭제</button>
            </div>);
    } else {
        actBtnHtml.push(
            <div className="d-flex justify-content-right gap-2 mt-3" style={{justifyContent: 'flex-end'}}>
                <button type="button" className="btn btn-secondary  m-1" onClick={oncancel}>취소</button>
                <button type="button" className="btn btn btn-primary m-1" onClick={onSave}>저장</button>                    
            </div>
        );
    }

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='FAQ 관리'>
                <Table bordered size="sm">
                <colgroup>
                    <col style={{width: '150px' }} />
                    <col style={{width: 'auto' }} />
                    <col style={{width: '150px' }} />
                    <col style={{width: 'auto' }} />
                </colgroup>
                <tbody>
                <tr>
                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}><span style={{color:"red"}}>＊</span> FAQ 유형</th>
                    <td colSpan={3}>
                        <RadioBox 
                        objectId = "faqTypeCd"
                        cdGrpId  = "FAQ_TYPE_CD" 
                        defValue = {saveParams.faqTypeCd}
                        onChange={handleRadioChange}
                        />
                    </td>
                </tr>
                <tr>
                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}><span style={{color:"red"}}>＊</span> 게시 여부</th>
                    <td colSpan={3}>
                        <RadioBox 
                            objectId = "pstgSttsCd"
                            cdGrpId  = "PSTG_STTS_CD" 
                            defValue = {saveParams.pstgSttsCd}
                            onChange={handleRadioChange} 
                        />
                    </td> 
                </tr>
                <tr>
                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}><span style={{color:"red"}}>＊</span>제목</th>
                    <td colSpan={3}><Form.Control type="text" id="faqTtl" name="faqTtl" onChange={inputHandleChange} value={saveParams.faqTtl} /></td>
                </tr>
                <tr>
                    <th style={{ textAlign: "center"  , verticalAlign: "middle"}}><span style={{color:"red"}}>＊</span>내용</th>
                    <td colSpan={3}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={content} // 초기 데이터
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(data); // 에디터 내용 업데이트
                                handleChange(data); // 에디터 내용 업데이트
                            }}
                        />
                        <div className="output">
                            <h3>Output:</h3>
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                    </td>
                </tr>
                </tbody>
                </Table>
                {actBtnHtml}
                </ContentLayout>
            </div>
        </div>



        
    )
}

export default FaqWrite;