import { getCodeGrpListAsync , getCodeGrpAsync , postCodeGrpAsync , putCodeGrpAsync , deleteCodeGrpAsync 
       , getCodeListAsync , getCodeAsync , postCodeAsync , putCodeAsync , deleteCodeAsync   } from './actions';

import { GET_SITEMNG_CODEGRP, GET_SITEMNG_CODEGRP_BY_ID, POST_SITEMNG_CODEGRP , PUT_SITEMNG_CODEGRP , DELETE_SITEMNG_CODEGRP
       , GET_SITEMNG_CODE , GET_SITEMNG_CODE_BY_ID , POST_SITEMNG_CODE , PUT_SITEMNG_CODE , DELETE_SITEMNG_CODE } from './actions';

import { getCodeGrpList, getCodeGrp , postCodeGrp , putCodeGrp , deleteCodeGrp 
       , getCodeList, getCode , postCode , putCode , deleteCode} from '../../api/siteMng';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getCodeGrpListSaga = createAsyncSaga(getCodeGrpListAsync, getCodeGrpList);
const getCodeGrpSaga = createAsyncSaga(getCodeGrpAsync, getCodeGrp);
const postCodeGrpSaga = createAsyncSaga(postCodeGrpAsync, postCodeGrp);
const putCodeGrpSaga = createAsyncSaga(putCodeGrpAsync, putCodeGrp);
const deleteCodeGrpSaga = createAsyncSaga(deleteCodeGrpAsync, deleteCodeGrp);

const getCodeListSaga = createAsyncSaga(getCodeListAsync, getCodeList);
const getCodeSaga = createAsyncSaga(getCodeAsync, getCode);
const postCodeSaga = createAsyncSaga(postCodeAsync, postCode);
const putCodeSaga = createAsyncSaga(putCodeAsync, putCode);
const deleteCodeSaga = createAsyncSaga(deleteCodeAsync, deleteCode);

export function* siteMngSaga() {
  yield takeEvery(GET_SITEMNG_CODEGRP       , getCodeGrpListSaga);
  yield takeEvery(GET_SITEMNG_CODEGRP_BY_ID , getCodeGrpSaga);
  yield takeEvery(POST_SITEMNG_CODEGRP      , postCodeGrpSaga);
  yield takeEvery(PUT_SITEMNG_CODEGRP       , putCodeGrpSaga);
  yield takeEvery(DELETE_SITEMNG_CODEGRP    , deleteCodeGrpSaga);

  yield takeEvery(GET_SITEMNG_CODE        , getCodeListSaga);
  yield takeEvery(GET_SITEMNG_CODE_BY_ID  , getCodeSaga); 
  yield takeEvery(POST_SITEMNG_CODE       , postCodeSaga);
  yield takeEvery(PUT_SITEMNG_CODE        , putCodeSaga);
  yield takeEvery(DELETE_SITEMNG_CODE     , deleteCodeSaga);
  
}
